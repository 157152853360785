import React from "react";

type IDataDisplay = {
  title: string;
  detail: string | React.ReactNode;
};

const DataDisplay: React.FC<IDataDisplay> = ({ title, detail }) => {
  return (
    <li className="list-group-item d-flex justify-content-between bg-transparent border-0">
      <div className="w-50 text-start">
        <span className="text-start">{title || "---"} : </span>
      </div>
      <div className="">{detail || "---"}</div>
    </li>
  );
};

export default DataDisplay;
