/* eslint-disable camelcase */
import React from "react";

// react router
import { useNavigate, useParams } from "react-router-dom";

// compoenents
import Company from "../misc/Company";
import UserFileLog from "../misc/UserFileLog";
import Button from "../../../component/ui/form/Button";
import DataDisplay from "../../../component/dataDisplay";
import MemberTag from "../../../component/ui/tabs/MemberTag";

// constants
import { HiOutlineArrowLongLeft } from "react-icons/hi2";

// state
import { UserOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_user_email } from "../../../services/apis/user";

const UserDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { email } = useParams();
  const navigate = useNavigate();

  // const { email, role, profile } = state;
  // const { company } = profile;
  //const loading = useAppSelector(({ user }) => user.loading);
  const user = useAppSelector(({ user }) => user.user);
  // const screenWidth = window.innerWidth;

  const onLoad = React.useCallback(() => {
    if (email) get_user_email(email, dispatch);
  }, [dispatch, email]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-2 py-3">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Users</h2>
        <div className="w-[50%] flex items-center justify-end ">
          <Button
            title="Back"
            children={<HiOutlineArrowLongLeft size={20} className="mr-2" />}
            onClick={() => navigate(-1)}
            classname="mr-2 bg-[#ddd] w-[35%] text-white"
          />
        </div>
      </div>

      <div className="flex flex-col mt-[20px]">
        <div className="w-full items-center flex justify-between px-2 py-3">
          <div className="w-[30%] flex items-center justify-center ">
            <div className="h-[100px] w-[100px] rounded-full border flex items-center justify-center">
              {user?.profile?.avatar ? (
                <img
                  src={user.profile.avatar}
                  alt={user.profile.name}
                  className="w-full h-full object-fill"
                />
              ) : (
                <UserOutlined />
              )}
            </div>
          </div>

          <div className="w-[70%]">
            <DataDisplay
              title="Name"
              detail={user?.profile?.name || "..."}
              className="capitalize"
            />
            <DataDisplay title="Email" detail={user?.email || "..."} />
            <DataDisplay
              title="Mobile Number"
              detail={user?.profile?.phone || "..."}
            />
            <DataDisplay
              title="Membership Type"
              detail={
                <MemberTag
                  value={user?.role === "0" ? "Member" : "Company Admin"}
                  className={
                    user?.role === "1"
                      ? "bg-[red] text-white"
                      : "text-white bg-[#ADD8E6]"
                  }
                />
              }
            />
          </div>
        </div>

        <div className="w-full items-center flex justify-between px-2 py-3">
          <Company company_id={user?.profile?.company_id || ""} />
          <UserFileLog email={email || ""} />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
