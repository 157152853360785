import axiosInstance from "../../utility/axios";

// state
import { openNotification } from "../../modules";
import {
  setCategories,
  createCategories,
  editCategory,
  deleteCategory,
  setBlogs,
  setBlog,
  createBlog,
  editBlog as updateBlog,
  setLoading,
  setComments,
  deleteBlog,
} from "../../store/slices/blog";
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";
import { ISimpleData, IUpdateStatus } from "../../store/types/misc";
import { ICreateBlog } from "../../store/types/blog";

export const get_categories = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-categories");

    const { data } = response.data;
    dispatch(setCategories(data));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      console.log(message);
    }
  }
  dispatch(setLoading());
};

export const create_category = async (
  form: { name: string },
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/v1/create-category", form);

    const { message, data } = response.data;

    dispatch(createCategories(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      console.log(message);
    }
  }
  dispatch(setLoading());
};

export const edit_category = async (
  form: ISimpleData,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch(`/v1/update-category`, form);

    const { message, data } = response.data;
    dispatch(editCategory(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err.response?.data;
      openNotification({
        type: "error",
        title: "Failed",
        description: message,
      });
    }
  }
  dispatch(setLoading());
};

export const delete_category = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(`/v1/delete-category/${id}`);

    const { message, data } = response.data;
    dispatch(deleteCategory(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err.response?.data;
      openNotification({
        type: "error",
        title: "Failed",
        description: message,
      });
    }
  }
  dispatch(setLoading());
};

/**
 *
 * @param dispatch
 */

export const get_blogs = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-blogs");

    const { data } = response.data;
    dispatch(setBlogs(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      console.log(message);
    }
  }
  dispatch(setLoading());
};

export const get_blog = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/v1/get-blog/${id}`);

    const { data } = response.data;
    dispatch(setBlog(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      console.log(message);
    }
  }
  dispatch(setLoading());
};

export const create_blog = async (form: ICreateBlog, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/v1/create-blog", form);

    const { message, data } = response.data;
    dispatch(createBlog(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err.response?.data;
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: message,
      });
    }
  }
  dispatch(setLoading());
};

export const edit_blog = async (form: ICreateBlog, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch(`/v1/update-blog`, form);

    const { data, message } = response.data;
    dispatch(updateBlog(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: message,
      });
    }
  }
  dispatch(setLoading());
};

export const update_status = async (
  form: IUpdateStatus,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch("/v1/update-blog-status", form);

    const { message, data } = response?.data;
    dispatch(updateBlog(data));
    openNotification({
      title: "Success",
      description: message,
      type: "success",
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      console.log(message);
      openNotification({
        title: "Success",
        description: message,
        type: "error",
      });
    }
  }
  dispatch(setLoading());
};

export const destroy_blog = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(`/v1/delete-blog/${id}`);

    const { message, data } = response.data;
    dispatch(deleteBlog(data));
    openNotification({
      type: "success",
      title: "Deleted",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err.response?.data;
      openNotification({
        type: "error",
        title: "Failed",
        description: message,
      });
    }
  }
  dispatch(setLoading());
};

/**
 * Comments
 */
export const get_comments = async (dispatch: AppDispatch, id: string) => {
  try {
    const response = await axiosInstance.get(
      `/v1/get-blog-comments?blog_id=${id}`
    );

    const { data } = response.data;
    dispatch(setComments(data));
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { message } = error?.response?.data;
      console.log(message);
    }
  }
};
