import React from "react";

// components
import { ResponsivePie } from "@nivo/pie";

// state and request
import { monthly_total as total_user } from "../../../services/apis/user";
import { monthly_total as total_company } from "../../../services/apis/company";
import { monthly_total as total_training } from "../../../services/apis/training";
import { useAppDispatch, useAppSelector } from "../../../store";

// utility
import {
  IPieChartObject,
  pie_data_generator,
} from "../../../utility/chart_converter";

const PieChart: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(({ user }) => user.monthly_total);
  const company = useAppSelector(({ company }) => company.monthly_total);
  const training = useAppSelector(({ training }) => training.month_total);

  const [data, dataSet] = React.useState<IPieChartObject[]>([]);

  const onLoad = React.useCallback(() => {
    total_user(dispatch);
    total_company(dispatch);
    total_training(dispatch);
  }, [dispatch]);

  const onSetData = React.useCallback(() => {
    dataSet((prev) => [
      pie_data_generator("user", user),
      pie_data_generator("company", company),
      pie_data_generator("training", training),
    ]);
  }, [company, training, user]);

  React.useEffect(() => {
    onLoad();
    onSetData();
  }, [onLoad, onSetData]);

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 70, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      // arcLinkLabelsSkipAngle={10}
      // arcLinkLabelsTextColor="#333333"
      // arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      // arcLabelsSkipAngle={10}
      // arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
    />
  );
};

export default PieChart;
