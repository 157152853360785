import axiosInstance from "../../utility/axios";

// state
import { openNotification } from "../../modules";
import { disableLoading } from "../../store/slices/loading";
import {
  setCompanyLoading,
  setCompany,
  setSearch,
  setCompanies,
  setCompanyDoc,
  setCompanyEmp,
  createCompany as newCompany,
  setStatusUpdate,
  setLoading,
  createDocument,
  setFileLogs,
  setTotal,
  setMonthTotal,
} from "../../store/slices/company";
import { isAxiosError } from "axios";
import { AppDispatch } from "../../store/store";
import { ICompanyDocument, ICreateCompany } from "../../store/types/company";

export const getAllCompany = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-all-company");
    if (response.data) {
    }
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(setLoading());
};

export const createCompany = async (
  data: ICreateCompany,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-company", data);

    openNotification({
      type: "success",
      title: "Successful!",
      description: "Company has been created.",
    });
    dispatch(newCompany(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(setLoading());
};

export const get_companies = async (
  dispatch: AppDispatch,
  page: number = 1
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-companies?page=${page}`);

    dispatch(setCompanies(response.data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(setLoading());
};

// /admin/toggle-company-status/6dd0d42a-803c-4745-b1c8-26947994445
export const getCompanyEmp = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/company-employees/${id}`);
    if (response.data) {
      dispatch(setCompanyEmp(response.data));
    }
  } catch (err) {
    // const { data } = err.response;
    // openNotification({
    //   type: "error",
    //   title: "Incorrect Credentials",
    //   description: data.message,
    // });
  }
  dispatch(setLoading());
};

export const getCompanyDoc = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/company-docs/${id}`);

    const { data } = response.data;
    dispatch(setCompanyDoc(data));
  } catch (err: any) {
    if (isAxiosError(err)) console.log("Err:", err?.response?.data);
  }
  dispatch(setLoading());
};

export const setRemoveUser = async (data: string, dispatch: AppDispatch) => {
  dispatch(setCompanyLoading());
  try {
    const response = await axiosInstance.get(`/remove-from-company/${data}`);

    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
  } catch (err: any) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data.message,
      });
    }
  }
  dispatch(disableLoading());
};

export const searchCompany = async (search: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/search-company/${search}`);
    if (response.data) {
      dispatch(setSearch(response.data));
    }
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(setLoading());
};

// Edited
export const company_status = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/v1/toggle-company-status/${id}`);

    const { message, data } = response.data;
    dispatch(setStatusUpdate(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(setLoading());
};

// Edited
export const premium_status = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/v1/toggle-company-premium/${id}`
    );

    const { message, data } = response.data;
    dispatch(setStatusUpdate(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(setLoading());
};

export const get_company = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-company/${id}`);

    const { data } = response.data;
    dispatch(setCompany(data));
  } catch (err) {
    if (isAxiosError(err)) {
      const { data } = err?.response?.data;
      console.log(data);
    }
  }
  dispatch(setLoading());
};

export const create_company_docs = async (
  form_data: ICompanyDocument,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/company-doc", form_data);

    const { data } = response.data;
    dispatch(createDocument(data));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({ type: "error", title: "Error", description: message });
    }
  }
  dispatch(setLoading());
};

export const rc_number_exists = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/get-company-by-rc/${id}`);

    const { data } = response.data;
    openNotification({
      type: "success",
      description: "RC Number exists.",
      title: "RC Number Found",
    });
    return data;
  } catch (err) {
    if (isAxiosError(err)) {
      const { data } = err?.response?.data;
      return data;
    }
  }
};

export const show_company = async (id: string, dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get(`/get-company/${id}`);

    const { data } = response.data;
    dispatch(setCompany(data));
  } catch (err) {
    if (isAxiosError(err)) {
      const { data } = err?.response?.data;
      return data;
    }
  }
};

export const get_user_logs = async (email: string, dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get(
      `/v1/get-user-document-logs/${email}`
    );

    const { data } = response.data;
    dispatch(setFileLogs(data));
  } catch (err) {
    if (isAxiosError(err)) {
      const { data } = err?.response?.data;
      return data;
    }
  }
};

export const get_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("v1/get-total-companies");

    const { data } = response.data;
    dispatch(setTotal(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      // Do something with error
    }
  }
  dispatch(setLoading());
};

export const monthly_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("v1/get-total-companies");

    const { data } = response.data;
    dispatch(setMonthTotal(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      // Do something with error
    }
  }
  dispatch(setLoading());
};
