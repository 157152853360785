import React from "react";

// state
import { IEditBlog, IEditErrorBlog } from "../../../store/types/blog";
import { useAppSelector } from "../../../store";

const useEditBlog = () => {
  const blog = useAppSelector(({ blog }) => blog.blog);
  const [image, imageSet] = React.useState<string>("");
  const [data, setData] = React.useState<IEditBlog>({
    title: "",
    image: "",
    article: "",
    blog_category_id: null,
    id: "",
  });

  const [error, setError] = React.useState<IEditErrorBlog>({
    article: "",
    blog_category_id: "",
    image: "",
    title: "",
    id: "",
  });

  const onValidate = () => {
    let err;
    if (!data.title) {
      err = true;
      setError((prev) => ({ ...prev, title: "warning" }));
    }
    if (!data.article) {
      err = true;
      setError((prev) => ({ ...prev, article: "warning" }));
    }
    if (!data.blog_category_id) {
      err = true;
      setError((prev) => ({ ...prev, blog_category_id: "warning" }));
    }
    if (!data.id) {
      err = true;
      setError((prev) => ({ ...prev, id: "warning" }));
    }
    if (err) return false;
    return true;
  };

  const onLoad = React.useCallback(() => {
    if (blog) {
      setData({
        article: blog?.article,
        blog_category_id: blog?.category?.id ? Number(blog.category?.id) : null,
        image: "",
        title: blog?.title,
        id: blog?.id,
      });
      imageSet(blog?.image);
    }
  }, [blog]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    image,
    data,
    error,
    setData,
    setError,
    onValidate,
  };
};

export default useEditBlog;
