import React from "react";

// react router
import { useParams } from "react-router-dom";

// compoenents
import DataDisplay from "../../../component/dataDisplay";
import Button from "../../../component/ui/form/Button";
import UploadDocument from "../misc/UploadDocument";
import DocumentList from "../../../component/DocumentList";
import CreateUser from "../../users/create";

// constants
import { VscFolderActive } from "react-icons/vsc";
import { FaFilePdf, FaRegBuilding } from "react-icons/fa";
import { format_date_time } from "../../../services/convert/date";
import office from "../../../assets/images/user/office.png";

// state
import { get_company } from "../../../services/apis/company";
import { useAppDispatch, useAppSelector } from "../../../store";
import CompanyStaff from "../misc/CompanyStaff";

const CompanyDetails: React.FC = () => {
  const { id } = useParams();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [visible, setVisible] = React.useState(false);
  const [upload, uploadSet] = React.useState<boolean>(false);

  const company = useAppSelector(({ company }) => company.company);
  // const loading = useAppSelector(({ company }) => company.loading);

  const onCloseModal = () => {
    setVisible(false);
  };

  const onLoad = React.useCallback(() => {
    if (id) get_company(id, dispatch);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-2 py-3">
      <div className="w-full flex justify-between items-center">
        <h2 className="font-bold text-[1.2rem] capitalize">Company</h2>

        <div className="w-[20%] flex items-center justify-end ">
          <Button
            title="Upload"
            children={<FaFilePdf className="mr-2" />}
            onClick={() => uploadSet(true)}
            classname="bg-primary text-white"
          />
        </div>
      </div>

      <div className="w-full my-3">
        <div className="w-full flex items-center mb-3">
          <div className="w-[35%] flex items-center justify-center">
            {company?.banner ? (
              <img
                src={company.banner}
                alt={company?.company_name}
                className=" bg-transparent w-32 h-32"
              />
            ) : (
              <img className="w-32 h-32" alt="zero user" src={office} />
            )}
          </div>

          <div className="w-[65%]">
            <ul className="list-group rounded-0">
              <DataDisplay
                title="Name"
                detail={
                  <span className="capitalize">{company?.company_name}</span>
                }
              />
              <DataDisplay
                title="Email"
                detail={
                  <span className="lowercase">{company?.company_email}</span>
                }
              />
              <DataDisplay
                title="Mobile Number"
                detail={company?.company_phone}
              />
              <DataDisplay
                title="Company Status"
                detail={
                  <VscFolderActive
                    size={23}
                    color={company?.status === "0" ? "" : "green"}
                  />
                }
              />
            </ul>
          </div>
        </div>

        <div className="w-full flex items-center justify-between mb-3">
          <div className="w-[44%]">
            <ul className="list-group rounded-0">
              <DataDisplay
                title="RC Number"
                detail={company?.rc_number || ""}
              />
              <DataDisplay
                title="Website"
                detail={company?.company_website || ""}
              />
              <DataDisplay
                title="Joined At"
                detail={
                  company?.created_at
                    ? format_date_time(company?.created_at)
                    : ""
                }
              />
              <DataDisplay
                title="Premium Status"
                detail={company?.premium_status || ""}
              />
            </ul>
          </div>

          <div className="w-[55%] h-[200px] border overflow-x-hidden overflow-y-auto">
            <p className="text-center  font-bold">Files</p>
            <DocumentList />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="kit__utils__table">
          <div>
            <h6 className="capitalize">
              {company?.company_name || ""} Employees
            </h6>
            <Button
              children={<FaRegBuilding />}
              classname=""
              onClick={() => setVisible(true)}
              title="Add Employees"
            />
          </div>

          <CompanyStaff
            branches={company?.branch.map((branch) => branch.id) || []}
          />

          <CreateUser
            companyID={id}
            visible={visible}
            onHandler={onCloseModal}
          />

          <UploadDocument visible={upload} onHandler={() => uploadSet(false)} />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
