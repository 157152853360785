import React from "react";
import { useNavigate } from "react-router-dom";

// components
import { Button, Form, Input } from "antd";
import { openNotification } from "../../../modules";

// themes
import AuthScreen from "../../../themes/guest";

// theme
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "../../../services/apis/auth";
import { get_cookie } from "../../../utility/cookies";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(({ loading }) => loading.loading);

  const onFinish = async ({ email, password }) => {
    // post Event
    authUser({ email, password }, dispatch, navigate);
  };

  const onFinishFailed = ({ errorFields }) => {
    errorFields.forEach((element) => {
      openNotification({
        type: "error",
        title: element?.name[0],
        description: element?.errors[0],
      });
    });
  };

  const onLoad = React.useCallback(() => {
    const serial = get_cookie("@serial");
    if (serial) navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <AuthScreen>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="form__container"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input your Email Address!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="margin_vertical"
            disabled={loading ? true : false}
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </AuthScreen>
  );
};

export default Login;
