import React from "react";

// state
import { ITrainingCreate, ITrainingError } from "../../../store/types/training";
import { useAppSelector } from "../../../store";

const useEditTraining = () => {
  const training = useAppSelector(({ training }) => training.training);
  const [image, imageSet] = React.useState<string>("");
  const [data, dataSet] = React.useState<ITrainingCreate & { id: string }>({
    name: "",
    body: "",
    address: "",
    link: "",
    date: "",
    duration: "",
    amount: "",
    image: "",
    state_id: "",
    country_id: "",
    id: "",
  });

  const [error, errorSet] = React.useState<ITrainingError>({
    name: "",
    body: "",
    state_id: "",
    address: "",
    link: "",
    amount: "",
    date: "",
    duration: "",
    image: "",
  });

  const onValidate = () => {
    let err;
    if (!data.name) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.body) {
      err = true;
      errorSet((prev) => ({ ...prev, body: "warning" }));
    }
    if (!data.state_id) {
      err = true;
      errorSet((prev) => ({ ...prev, state_id: "warning" }));
    }
    if (!data.amount) {
      err = true;
      errorSet((prev) => ({ ...prev, amount: "warning" }));
    }
    if (!data.date) {
      err = true;
      errorSet((prev) => ({ ...prev, date: "warning" }));
    }
    if (!data.duration) {
      err = true;
      errorSet((prev) => ({ ...prev, duration: "warning" }));
    }
    if (err) return false;
    return true;
  };

  const onLoad = React.useCallback(() => {
    if (training) {
      dataSet({
        name: training.name,
        body: training.body,
        address: training.address,
        link: training.link,
        date: training?.date?.toString() || "",
        duration: training?.duration,
        amount: training?.amount,
        image: "",
        state_id: training?.state?.id,
        country_id: training?.state?.country?.id,
        id: training?.id,
      });
      imageSet(training.image);
    }
  }, [training]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    image,
    error,
    dataSet,
    errorSet,
    onValidate,
  };
};

export default useEditTraining;
