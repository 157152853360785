import React from "react";

// react router
import { useParams } from "react-router-dom";

// compoenents
import RequestStep from "../misc/RequestStep";
// import DocumentButton from "../misc/DocumentButton";
import { EditPrice } from "../misc/EditPrice";
import ViewDocument from "../misc/ViewDocument";
import DataList from "../misc/DataList";
import Button from "../../../component/ui/form/Button";
import { UploadDocument } from "../misc/UploadDocument";

// constants
import { BsPencilFill, BsFilePdf } from "react-icons/bs";
import { IoMdCopy } from "react-icons/io";
import {
  format_date_time,
  real_date,
  real_date_time,
} from "../../../services/convert/date";

// state and service
import {
  add_price_on_request,
  cancel_request,
  get_request,
  upload_document,
} from "../../../services/apis/requests";
import { useAppDispatch, useAppSelector } from "../../../store";

const ShowRequest = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const request = useAppSelector(({ request }) => request.request);
  // const update = useAppSelector(({ request }) => request.update);
  const [show, showSet] = React.useState(false);
  const [file, fileSet] = React.useState(false);
  const [upload, uploadSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    if (id) get_request(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      {request?.request_status && (
        <RequestStep current={request.request_status} />
      )}

      <div className="flex w-full items-center justify-between mt-4">
        <div className="w-[40%]">
          {request?.service ? (
            <img
              alt={request?.service?.name}
              src={request?.service?.image}
              className="w-full"
            />
          ) : (
            <BsFilePdf
              size={150}
              color="#67C748"
              className="cursor-pointer"
              onClick={() => fileSet(true)}
            />
          )}
        </div>

        <div className="w-[59%]">
          <DataList
            title="Name"
            name={request?.service?.name || request?.document?.name || ""}
          />
          <DataList
            title="Email"
            name={request?.user?.email || ""}
            icon={
              <IoMdCopy
                size={18}
                className="cursor-pointer ml-2"
                onClick={async () =>
                  await navigator.clipboard.writeText(
                    request?.user?.email || ""
                  )
                }
              />
            }
          />
          <DataList
            title="Payment Link"
            name={request?.payment_link || ""}
            icon={
              <div className="mx-2 flex items-center">
                {request?.payment_link && (
                  <>
                    <a
                      href={request.payment_link}
                      rel="noreferrer"
                      target="_blank"
                      className="px-2 py-[3px] bg-[#67C748] rounded-sm text-[white]"
                    >
                      Visit Link
                    </a>
                    <IoMdCopy
                      size={18}
                      className="cursor-pointer mr-2"
                      onClick={async () =>
                        await navigator.clipboard.writeText(
                          request.payment_link
                        )
                      }
                    />
                  </>
                )}
                <BsPencilFill
                  className="cursor-pointer"
                  onClick={() => showSet(!show)}
                />
              </div>
            }
          />
          <DataList
            title="Location"
            name={
              request?.state?.name
                ? `${request?.state?.name}, ${request?.state?.country?.name}`
                : ""
            }
          />
          <DataList
            title="Created"
            name={request?.created_at ? real_date_time(request.created_at) : ""}
          />
        </div>
      </div>

      <div className="w-full flex justify-center mt-3">
        <div className="w-[45%]">
          <div className="flex w-full justify-evenly">
            <div className="w-[40%]">
              <Button
                title="Upload Document"
                type="button"
                onClick={() => uploadSet(true)}
                classname="bg-primary-500 text-[white]"
              />
            </div>
            <div className="w-[40%]">
              <Button
                title="Cancel"
                type="button"
                onClick={() => cancel_request(dispatch)}
                classname="bg-[red] text-[white]"
              />
            </div>
          </div>
          <p className="text-[#67C748] text-bold text-[15px] mt-2">Price(s)</p>
          <div className="overflow-y-auto w-full px-2 h-[200px]">
            {request?.price?.map((item, index) => (
              <div
                className={`border-b text-center ${
                  index === 0 ? "text-[#67C748]" : "text-[red]"
                }`}
                key={index}
              >
                <span className="block">{item?.price}</span>
                <span>{`${item?.created_at && real_date(item?.created_at)} (${item?.submitted_by || ""})`}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="w-[45%] flex items-center justify-center">
          {request?.document ? (
            <div className="">
              <DataList name={request?.document?.name || ""} title="Name" />
              <DataList
                name={request?.document?.issuing_body || ""}
                title="Issuing Body"
              />
              <DataList
                name={
                  request?.document?.issuing_date
                    ? format_date_time(request?.document?.issuing_date)
                    : ""
                }
                title="Issued Date"
              />
              <DataList
                name={
                  request?.document?.expiring_date
                    ? format_date_time(request?.document?.expiring_date)
                    : ""
                }
                title="Expire Date"
              />
            </div>
          ) : (
            request?.service?.packages?.map((item, index) => (
              <div
                key={item.id}
                className={`w-full text-center capitalize text-[15px] border-b`}
              >
                {item.name}
              </div>
            ))
          )}
        </div>
      </div>

      <div className="mt-2">
        <span className="text-[1.rem] font-bold">Other Requirement</span>
        <p
          dangerouslySetInnerHTML={{ __html: request?.others || "" }}
          className="w-full overflow-y-auto"
        />
      </div>

      {/* <div className="w-full">
        <div className="w-full flex justify-between">
          <div className="w-[39%] py-3">
            <div className="flex items-center">
              <DocumentButton current={request?.request_status?.id || 1} />

              <button
                type="button"
                className="px-4 py-2 mx-2 bg-[#e82113] text-white text-[13px] font-bold rounded-sm"
                onClick={() => cancel_request(dispatch, { id, status: "3" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {request?.document && (
        <ViewDocument
          title={request.document.name || ""}
          path={request.document.path || ""}
          visible={file}
          onCloseView={() => fileSet(false)}
        />
      )}
      {id && (
        <EditPrice
          id={id}
          visible={show}
          onCloseView={(res) => {
            if (res.price && res.payment_link && res.id)
              add_price_on_request(dispatch, res);
            showSet(false);
          }}
        />
      )}
      <UploadDocument
        show={upload}
        onCloseView={(res) => {
          if (res.id && res.path) upload_document(dispatch, res);
          uploadSet(false);
        }}
      />
    </div>
  );
};

export default ShowRequest;
