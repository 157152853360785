import React from "react";

// component
import { Skeleton } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_total } from "../../../services/apis/company";
import { get_total_user } from "../../../services/apis/user";

const TotalCompanyUser = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ company }) => company.loading);
  const loading_u = useAppSelector(({ user }) => user.loading);
  const company = useAppSelector(({ company }) => company.total);
  const user = useAppSelector(({ user }) => user.total);

  const onLoad = React.useCallback(() => {
    get_total(dispatch);
    get_total_user(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full h-[120px] shadow-sm rounded-2xl p-4 flex justify-between bg-white">
      <h5 className="text-lg font-medium">Company</h5>

      <div>
        <div>
          {loading ? (
            <Skeleton.Button active={true} block />
          ) : (
            <h5 className="text-right text-4xl font-bold">{company}</h5>
          )}
        </div>

        <div className="flex items-center justify-center gap-2">
          <h5>User </h5>
          {loading_u ? (
            <Skeleton.Button
              active={true}
              // block
              // style={{ height: "20px", width: "10px" }}
            />
          ) : (
            <span className="!text-primary self-cente">{user}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalCompanyUser;
