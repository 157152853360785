import React from "react";

// state
import { useAppDispatch, useAppSelector } from "../../../store";
import { IService } from "../../../store/types/service";
import { delete_service, get_services } from "../../../services/apis/services";

// components
import EditService from "../edit";
import CreateService from "../create";
import Button from "../../../component/ui/form/Button";
import DataTable from "../../../component/element/table";
import ItemAction from "../../../component/ui/action/ItemAction";

// constants and icon
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const services = useAppSelector(({ service }) => service?.services);
  // const loading = useAppSelector(({ service }) => service.loading);

  const [show, showSet] = React.useState<boolean>(false);
  const [edit, editSet] = React.useState<string>("");

  const onLoad = React.useCallback(() => {
    get_services(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: ColumnDef<IService>[] = [
    {
      accessorKey: "image",
      header: "Image",
      cell: ({ getValue }) => {
        const image = getValue() as string;
        return (
          <img
            src={image}
            alt={image}
            className="w-[3rem] h-[3rem] overflow-hidden rounded-md"
          />
        );
      },
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ getValue }) => {
        const name = getValue() as string;
        return name;
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue, row }) => {
        const status = getValue() as string;
        return (
          <span
            className={`text-[white] py-1 px-2 rounded-lg ${status === "1" ? "bg-primary" : "bg-gray"}`}
          >
            {status === "1" ? "active" : "not active"}
          </span>
        );
      },
    },
    {
      accessorKey: "id",
      header: "Action",
      cell: ({ getValue }) => {
        const id = getValue() as string;
        return (
          <ItemAction
            onView={() => navigate(`/services/${id}`)}
            onDelete={() => delete_service(dispatch, id)}
            onEdit={() => editSet(id)}
          />
        );
      },
    },
  ];

  return (
    <div className="w-full px-2 py-2 bg-[white]">
      <div className="w-full flex justify-between items-center px-2 py-1 overflow-hidden">
        <h2 className="font-bold text-[1.2em] capitalize">Services</h2>

        <div className="w-[20%]">
          <Button
            type="button"
            title="Create"
            onClick={() => showSet(true)}
            classname="bg-[#67C748] text-white"
          />
        </div>
      </div>

      <div className="flex flex-1 w-full flex-col mt-3">
        <DataTable columns={columns} data={services} />
      </div>

      <CreateService show={show} onCancel={() => showSet(false)} />
      {edit && (
        <EditService
          show={edit ? true : false}
          id={edit}
          onCancel={() => editSet("")}
        />
      )}
    </div>
  );
};

export default Services;
