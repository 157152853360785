import React from "react";

type ITextBox = {
  label?: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (data: string, value: string) => void;
};

const TextBox: React.FC<ITextBox> = ({
  label,
  name,
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className="w-full">
      {label && <div className="w-full block mb-[2px] font-bold">{label}</div>}

      <div className="w-full">
        <textarea
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
          className="w-full px-[2px] py-[1px] outline-none focus:outline-none placeholder:text-[15px] h-[200px] border"
        />
      </div>
    </div>
  );
};

export default TextBox;
