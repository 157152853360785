import React from "react";
import Button from "../../element/forms/Button";
import { ISimpleData } from "../../../store/types/misc";

type TItem = ISimpleData & { button_name: string };

type TSimpleTab = {
  items: TItem[];
  focused: TItem;
  onClick: (data: TItem) => void;
  onButtonClick: () => void;
};

const SimpleTab: React.FC<TSimpleTab> = ({
  items,
  focused,
  onClick,
  onButtonClick,
}) => {
  return (
    <div className="flex justify-between items-center w-full px-6 py-6">
      <div className="flex items-center text-[#313b5e] capitalize">
        {items.map((item) => (
          <h3
            key={item.id}
            onClick={() => onClick(item)}
            className={`font-bold cursor-pointer px-2 mr-3 py-1.5  ${
              focused.id === item.id &&
              "text-primary-500 border-b border-primary-800"
            }`}
          >
            {item.name}
          </h3>
        ))}
      </div>

      <div className="w-[20%]">
        <Button
          title={focused.button_name || items?.[0]?.name || ""}
          type="button"
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default SimpleTab;
