import React from "react";

// components
import { FaRegBuilding } from "react-icons/fa";
import { Table, TableProps, Popconfirm, Spin } from "antd";
import Button from "../../../component/ui/form/Button";
import EditCategory from "../misc/EditCategory";
import CreateCategory from "../misc/CreateCategory";

// constants and icon
import { HiArrowLongLeft } from "react-icons/hi2";
import { FaTrash, FaEdit } from "react-icons/fa";

// router and dispatch
import { get_categories, delete_category } from "../../../services/apis/blog";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ISimpleData } from "../../../store/types/misc";

const BlogCategory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const categories = useAppSelector(({ blog }) => blog.categories);
  const loading = useAppSelector(({ blog }) => blog.loading);

  const [show, showSet] = React.useState<boolean>(false);
  const [edit, editSet] = React.useState<{
    show: boolean;
    data: ISimpleData | null;
  }>({
    show: false,
    data: null,
  });

  const onLoad = React.useCallback(() => {
    get_categories(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: TableProps<ISimpleData>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (e) => <span>{e}</span>,
    },
    {
      title: "Action",
      key: "id",
      align: "center",
      dataIndex: "id",
      render: (id, data) => (
        <div className="flex w-full items-center justify-evenly">
          <FaEdit
            size={20}
            color="blue"
            onClick={() => {
              editSet({ show: true, data });
            }}
          />
          <Popconfirm
            title="Are you sure you want to delete."
            okText="Confirm"
            cancelText="Cancel"
            onCancel={() => null}
            onConfirm={() => delete_category(id, dispatch)}
            className="cursor-pointer"
          >
            <FaTrash size={20} color="red" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Category</h2>

        <div className="w-[50%] flex items-center justify-end ">
          <Button
            title="Create"
            children={
              <>
                {loading && <Spin className="mr-1" />}
                <FaRegBuilding className="mr-2" />
              </>
            }
            onClick={() => showSet(true)}
            classname="mx-4 bg-[#67C748] w-[30%] text-white"
          />

          <Button
            title="Back"
            children={<HiArrowLongLeft className="mr-2" />}
            onClick={() => navigate(-1)}
            classname="mr-2 bg-[#ddd] w-[35%] text-white"
          />
        </div>
      </div>

      <div className="mt-[20px] flex flex-1 flex-col ">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={categories}
          // loading={loading}
          className="mb-2"
          pagination={false}
        />
      </div>

      <CreateCategory show={show} onModal={() => showSet(false)} />

      {edit.data !== null && (
        <EditCategory
          data={edit.data}
          show={edit.show}
          onModal={() => editSet({ show: false, data: null })}
        />
      )}
    </div>
  );
};

export default BlogCategory;
