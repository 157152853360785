import React from "react";

// router
import { useNavigate } from "react-router-dom";

// state
import { useDispatch } from "react-redux";
import { get_companies } from "../../../services/apis/company";

const useDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [visible, setVisible] = React.useState(false);
  const [viewEdit, setViewEdit] = React.useState(false);
  const [viewOne, setViewOne] = React.useState(false);
  const [viewOp, setViewOp] = React.useState({});
  const [edit, setEdit] = React.useState({});

  const onSearch = (value) => console.log(value);

  const onCloseModal = () => {
    setVisible(false);
  };
  const editOpen = (data) => {
    setEdit(data);
    console.log(data);
    setViewEdit(true);
  };
  const viewOpen = (data) => {
    setViewOp(data);
    setViewOne(true);
  };
  const onCloseView = () => {
    setViewOne(false);
  };
  const onCloseEdit = () => {
    setViewEdit(false);
  };

  const onLoad = React.useCallback(() => {
    get_companies(dispatch, navigate, page);
  }, [dispatch, navigate, page]);

  return {
    onLoad,
    setPage,
    editOpen,
    viewOpen,
    onCloseEdit,
    onCloseView,
    setEdit,
    viewOne,
    viewOp,
    edit,
    viewEdit,
    visible,
    setVisible,
    onCloseModal,
    onSearch,
    page,
  };
};

export default useDashboard;
