import React from "react";

// components
import TypeTable from "../misc/TypeTable";
import OpportunityTable from "../misc/OpportunityTable";
import CreateOpportunity from "../misc/CreateOportunity";
import SimpleTab from "../../../component/ui/tabs/SimpleTab";
import CreateOppotunityType from "../misc/CreateOppotunityType";

// state
import { useAppDispatch } from "../../../store";
import { get_opportunities } from "../../../services/apis/opportunity";
import { ISimpleData } from "../../../store/types/misc";

const Opportunity = () => {
  const dispatch = useAppDispatch();
  const [focused, focusedSet] = React.useState<
    ISimpleData & { button_name: string }
  >({
    id: "opportunity",
    name: "Opportunity",
    button_name: "Create",
  });

  const [create, createSet] = React.useState<boolean>(false);
  const [type, typeSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    get_opportunities(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full h-full bg-[white]">
      <div>
        <SimpleTab
          items={[
            { id: "opportunity", name: "Opportunity", button_name: "Create" },
            { id: "type", name: "Type", button_name: "Create" },
          ]}
          onClick={(res) => focusedSet(res)}
          onButtonClick={() => {
            if (focused.id === "opportunity") createSet(true);
            if (focused.id === "type") typeSet(true);
          }}
          focused={focused}
        />
      </div>

      <div className="w-full h-full">
        {focused.id === "opportunity" && <OpportunityTable />}
        {focused.id === "type" && <TypeTable />}
      </div>

      <CreateOpportunity visible={create} onHandler={() => createSet(false)} />
      <CreateOppotunityType visible={type} onClose={() => typeSet(false)} />
    </div>
  );
};

export default Opportunity;
