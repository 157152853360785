import React from "react";

// state
import { ICreateBlog, ICreateErrorBlog } from "../../../store/types/blog";

const useCreateBlog = () => {
  const [data, setData] = React.useState<ICreateBlog>({
    title: "",
    image: "",
    article: "",
    blog_category_id: null,
  });

  const [error, setError] = React.useState<ICreateErrorBlog>({
    article: "",
    blog_category_id: "",
    image: "",
    title: "",
  });

  const onValidate = () => {
    let err;
    if (!data.title) {
      err = true;
      setError((prev) => ({ ...prev, title: "warning" }));
    }
    if (!data.article) {
      err = true;
      setError((prev) => ({ ...prev, article: "warning" }));
    }
    if (!data.blog_category_id) {
      err = true;
      setError((prev) => ({ ...prev, blog_category_id: "warning" }));
    }
    if (!data.image) {
      err = true;
      setError((prev) => ({ ...prev, image: "warning" }));
    }
    if (err) return false;
    return true;
  };

  return {
    data,
    error,
    setData,
    setError,
    onValidate,
  };
};

export default useCreateBlog;
