import React from "react";

// components
import { Tabs, TabsProps } from "antd";
import Profile from "../misc/Profile";
import ChangePassword from "../misc/ChangePassword";
import ListAdminUser from "../misc/ListAdminUser";

const Setting = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "My Profile",
      children: <Profile />,
    },
    {
      key: "2",
      label: "Change Password",
      children: <ChangePassword />,
    },
    {
      key: "3",
      label: "Admin Users",
      children: <ListAdminUser />,
    },
  ];

  return (
    <div className="w-full h-full overflow-y-auto p-2">
      <Tabs
        defaultActiveKey="1"
        items={items}
        // onChange={() => console.log("Onchange")}
      />
    </div>
  );
};

export default Setting;
