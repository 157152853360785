import GitInfo from "react-git-info/macro";
// REACT_APP_WHALE_URL_DEVELOPMENT

const git = GitInfo();

const local = {
  url: process.env.REACT_APP_BASE_URL_ENVIRONMENT,
  crypt: process.env.REACT_APP_AUTH_KEY_DEVELOPMENT,
};

const dev = {
  url: process.env.REACT_APP_BASE_URL_DEVELOPMENT,
  crypt: process.env.REACT_APP_AUTH_KEY_DEVELOPMENT,
};

const whale = {
  url: process.env.REACT_APP_WHALE_URL_DEVELOPMENT,
  crypt: process.env.REACT_APP_AUTH_KEY_DEVELOPMENT,
};

const prod = {
  url: process.env.REACT_APP_BASE_URL_PRODUCTION,
  crypt: process.env.REACT_APP_AUTH_KEY_PRODUCTION,
};

export default git && (git.branch === "main" || git.branch === "prod")
  ? prod
  : git && git.branch === "dev"
    ? dev
    : git && git.branch === "whalinga"
      ? whale
      : local;
