import React from "react";

// components
import DataDisplay from "../../../component/dataDisplay";

// state and dispatch
import { show_company } from "../../../services/apis/company";
import { useAppDispatch, useAppSelector } from "../../../store";

type ICompany = {
  company_id: string;
};

const Company: React.FC<ICompany> = ({ company_id }) => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(({ company }) => company.company);
  // const loading = useAppSelector(({ company }) => company.loading);

  const onLoad = React.useCallback(() => {
    if (company_id) show_company(company_id, dispatch);
  }, [company_id, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-[60%]">
      <div>Company Details</div>
      <div>
        <DataDisplay
          title="Company Name"
          detail={company?.company_name || ""}
        />
        <DataDisplay
          title="Company Email"
          detail={company?.company_email || ""}
        />
        <DataDisplay
          title="Company Mobile Number"
          detail={company?.company_phone || ""}
        />
        <DataDisplay
          title="Company Website"
          detail={company?.company_website || ""}
        />
        <DataDisplay
          title="Company RC-Number"
          detail={company?.rc_number || ""}
        />
        <DataDisplay
          title="Company Premium Status"
          detail={company?.premium_status || ""}
        />
        <DataDisplay title="Company Status" detail={company?.status || ""} />
      </div>
    </div>
  );
};

export default Company;
