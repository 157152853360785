/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from "../../utility/axios";

// state
import { openNotification } from "../../modules";
import {
  setUpdate,
  // setServiceRequestLoading,
  setServiceRequest,
  setRequestStatus,
  setServiceRequests,
  setLoading,
  setTotal,
  setBarGraph,
} from "../../store/slices/request";
// import { save_document } from "./upload";
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";
import { IPriceUpdate, IUploadDocument } from "../../store/types/request";

export const get_service_requests = async (
  dispatch: AppDispatch,
  current_page: number
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/get-requests?page=${current_page}`
    );

    dispatch(setServiceRequests(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data.message,
      });
    }
  }
  dispatch(setLoading());
};

export const get_request = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/v1/get-request/${id}`);

    const { data } = response.data;
    dispatch(setServiceRequest(data));
  } catch (err) {
    if (isAxiosError(err)) {
      // console.log(err.response?.data);
    }
  }
  dispatch(setLoading());
};

export const createServiceRequest = async (
  formData: any,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-services", formData);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err.response?.data.message,
      });
    }
  }
  dispatch(setLoading());
};

export const editServiceRequest = async (data: any, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.put(`/edit-training`, data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data.message,
      });
    }
  }
  dispatch(setLoading());
};

export const add_price_on_request = async (
  dispatch: AppDispatch,
  data: IPriceUpdate
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-request-price", data);

    const { message, data: service } = response.data;
    dispatch(setServiceRequest(service));
    openNotification({
      type: "success",
      description: message,
      title: "Success",
    });
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(setUpdate());
};

export const cancel_request = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.patch("/update-status");

    // dispatch(setRequest(response.data));
    console.log(response.data);
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
};

export const upload_document = async (
  dispatch: AppDispatch,
  data: IUploadDocument
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-document", data);

    openNotification({
      title: "Completed.",
      description: response?.data?.message,
      type: "success",
    });
  } catch (err) {
    if (isAxiosError(err))
      openNotification({
        title: "Cannot update this file.",
        description: err?.response?.data?.message,
        type: "error",
      });
  }
  dispatch(setUpdate());
};

export const get_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-total-request");

    const { data } = response.data;
    dispatch(setTotal(data));
  } catch (error) {
    if (isAxiosError(error)) console.log(error?.response?.data);
  }
  dispatch(setLoading());
};

export const get_all_request_histogram = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-all-request-chart");

    const { data } = response.data;
    dispatch(setBarGraph(data));
  } catch (error) {
    if (isAxiosError(error)) console.log("Error:", error?.response?.data);
  }
  dispatch(setLoading());
};

export const get_statuses = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/v1/request-statuses");

    const { data } = response;
    dispatch(setRequestStatus(data));
  } catch (err) {
    if (isAxiosError(err)) return false;
  }
};
