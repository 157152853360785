import React from "react";
import useCreateBlog from "../hooks/useCreateBlog";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import RichText from "../../../component/element/forms/RichText";
import TextInput from "../../../component/element/forms/TextInput";
import SelectOption from "../../../component/element/forms/SelectOption";
import ButtonComponent from "../../../component/element/forms/Button";
import Upload from "../../../component/modals/upload";
import Button from "../../../component/ui/form/Button";

// constants
import { MdSubtitles } from "react-icons/md";
import { FaLongArrowAltLeft } from "react-icons/fa";

// state
import { create_blog, get_categories } from "../../../services/apis/blog";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";

const CreateBlog: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const categories = useAppSelector(({ blog }) => blog.categories);
  const loading = useAppSelector(({ blog }) => blog.loading);

  const { uploadAvatar, img_loader } = useUploadFiles();

  const { data, error, setData, setError, onValidate } = useCreateBlog();

  const handleOk = () => {
    const validate = onValidate();
    if (!validate) return;
    create_blog(data, dispatch);
  };

  const onLoad = React.useCallback(() => {
    get_categories(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full ">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Edit Blog</h2>
        <div className="w-[50%] flex items-center justify-end ">
          <Button
            title="Back"
            children={<FaLongArrowAltLeft className="mr-2" />}
            onClick={() => navigate(-1)}
            classname="mr-2 bg-[#ddd] w-[35%] text-white"
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center mb-4">
        <div className="w-[70%]">
          <div className="flex w-full items-center justify-between my-4">
            <div className="w-[49%]">
              <TextInput
                name="title"
                type="text"
                label="Title"
                value={data.title}
                placeholder="Enter Title"
                icon={<MdSubtitles size={20} />}
                status={error.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value, name } = e.target;
                  setData((prev) => ({ ...prev, [name]: value }));
                  setError((prev) => ({ ...prev, [name]: "" }));
                }}
              />
            </div>

            <div className="w-[49%]">
              <SelectOption
                items={categories}
                label="Category"
                placeholder="Select Category"
                value={data.blog_category_id?.toString()}
                onChange={(res) => {
                  setData((prev) => ({
                    ...prev,
                    blog_category_id: Number(res),
                  }));
                  setError((prev) => ({ ...prev, blog_category_id: "" }));
                }}
              />
            </div>
          </div>

          <div className="w-full my-4">
            <Upload
              title="Upload Image"
              name="blog_file"
              loading={img_loader}
              status={error.image}
              completed={data.image ? true : false}
              onChange={async (res) => {
                const avatar = await uploadAvatar(res);
                if (typeof avatar === "string") {
                  setData((prev) => ({ ...prev, image: avatar }));
                  setError((prev) => ({ ...prev, image: "" }));
                }
              }}
            />
          </div>

          <div className="w-full my-4">
            <RichText
              value={data.article}
              placeholder="Write Article"
              onChange={(res) => setData((prev) => ({ ...prev, article: res }))}
            />
          </div>

          <div className="flex justify-end items-center">
            <div className="w-[20%]">
              <ButtonComponent
                type="submit"
                title="Submit"
                loading={loading}
                onClick={handleOk}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlog;
