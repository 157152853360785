/* eslint-disable no-unused-vars */
import React from "react";

// components
import { ISimpleData } from "../../../store/types/misc";

type ISelectOption = {
  label: string;
  placeholder: string;
  loading?: boolean;
  value?: string;
  icon?: React.ReactNode;
  onChange: (selected: string) => void;
  items: ISimpleData[];
  name?: string;
};

const SelectOption: React.FC<ISelectOption> = ({
  label,
  items,
  icon,
  value,
  name,
  onChange,
}) => {
  return (
    <div className="w-full">
      {label && <div className="w-full block mb-[2px] font-bold">{label}</div>}
      <div className="flex w-full py-[8px] rounded-sm border border-[#000000] hover:border-[#67C748] overflow-hidden">
        {icon && (
          <div className="border-r w-[40px] flex justify-center items-center">
            {icon}
          </div>
        )}
        <div className="flex-1 px-[6px]">
          <select
            value={value || ""}
            name={name}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const { value } = e.target;
              onChange(value);
            }}
            className="w-full bg-[white] h-full hover:cursor-pointer"
          >
            <option value="">Select</option>
            {items.map((item) => (
              <option key={item.id} value={item.id} className="">
                {item.name}{" "}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectOption;
