import React from "react";

import { Spin } from "antd";

export type IButton = {
  title: string;
  type: "submit" | "button";
  loading?: boolean;
  onClick: () => void;
};

const Button: React.FC<IButton> = ({ title, type, loading, onClick }) => {
  return (
    <button
      type={type || "button"}
      onClick={onClick}
      className="w-full bg-[#67C748] border-none py-2 font-bold text-white text-center rounded-sm"
    >
      {loading && <Spin className="mx-2" size="small" />}
      {title}
    </button>
  );
};

export default Button;
