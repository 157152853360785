import React from "react";

// components
import { Spin } from "antd";
import EditTraining from "../misc/EditTraining";
import Button from "../../../component/ui/form/Button";

// constants
import { CgArrowLongLeft } from "react-icons/cg";
import { BiWorld } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { format_date_time } from "../../../services/convert/date";

// state
import { useNavigate, useParams } from "react-router-dom";
import { get_training } from "../../../services/apis/training";
import { useAppDispatch, useAppSelector } from "../../../store";

const ViewTraining = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const training = useAppSelector(({ training }) => training.training);
  const loading = useAppSelector(({ training }) => training.loading);

  const [show, showSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    if (id) get_training(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Training</h2>
        <div className="w-[50%] flex items-center justify-end">
          <Button
            title="Edit"
            children={
              <>
                {loading && <Spin className="mr-1" />}
                <FaEdit className="mr-2" />
              </>
            }
            onClick={() => showSet(true)}
            classname=" bg-[#67C748] w-[30%] text-white"
          />
          <Button
            title="Back"
            children={<CgArrowLongLeft className="mr-2" />}
            onClick={() => navigate(-1)}
            classname="ml-4 bg-[#3b3939] w-[30%] text-white"
          />
        </div>
      </div>

      <div className="w-full flex items-center h-[100%] mt-5">
        <div className="w-[50%] p-2">
          <img src={training?.image} alt={training?.name} />
        </div>
        <div className="w-[50%]">
          <h2 className="text-[20px] font-bold">{training?.name}</h2>
          <h2 className="text-[17px] font-bold">
            Address: {training?.address}
          </h2>
          <h2 className="text-[16px]">Amount: {training?.amount}</h2>

          <h2 className="text-[16px] flex items-center">
            Date: {training?.date ? format_date_time(training?.date) : ""}
            <BiWorld
              className="ml-3 cursor-pointer"
              onClick={() => navigate(training?.link || "")}
              size={25}
            />
          </h2>

          <div className="flex items-center ">
            <h2 className="text-[16px] w-[50%]">Date: {training?.duration}</h2>
            <h2 className="text-[16px] w-[50%]">
              Location: {training?.state?.name},{" "}
              {training?.state?.country?.name}
            </h2>
          </div>

          <div dangerouslySetInnerHTML={{ __html: training?.body || "" }} />
        </div>
      </div>

      {id && (
        <EditTraining id={id} show={show} onHandler={() => showSet(false)} />
      )}
    </div>
  );
};

export default ViewTraining;
