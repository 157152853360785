import React from "react";

// components
import DataTable from "../../../component/element/table";

// types and dispatch
import {
  delete_type,
  get_opportunities_type,
} from "../../../services/apis/opportunity";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ColumnDef } from "@tanstack/react-table";
import { IOpportunityTypes } from "../../../store/types/opportunity";
import { MdOutlineDelete } from "react-icons/md";

const TypeTable = () => {
  const dispatch = useAppDispatch();
  const types = useAppSelector(
    ({ opportunity }) => opportunity.opportunity_types
  );

  const onLoad = React.useCallback(() => {
    get_opportunities_type(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: ColumnDef<IOpportunityTypes>[] = [
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ getValue }) => {
        const name = getValue() as string;
        return name;
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }) => {
        const status = getValue() as string;
        return (
          <span
            className={`px-2 py-1 text-[white] rounded-lg ${status === "1" ? "bg-primary" : "bg-red"}`}
          >
            {status === "1" ? "active" : "not active"}
          </span>
        );
      },
    },
    {
      accessorKey: "id",
      header: "Action",
      cell: ({ getValue }) => {
        const id = getValue() as string;
        return (
          <div className="flex">
            <MdOutlineDelete
              size={20}
              onClick={() => delete_type(id, dispatch)}
              className="cursor-pointer"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DataTable columns={columns} data={types} />
    </>
  );
};

export default TypeTable;
