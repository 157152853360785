import React from "react";

// router
import { BrowserRouter, Route, Routes } from "react-router-dom";

// pages
import Layout from "../pages/layout";
import { Login } from "../pages/auth";

const EntryRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Layout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default EntryRoute;
