import React from "react";

// components
import TextInput from "../../../component/element/forms/TextInput";
import Button from "../../../component/element/forms/Button";

// state
import { useAppDispatch, useAppSelector } from "../../../store";
import { create_type } from "../../../services/apis/opportunity";
import Modal from "../../../component/ui/modal";
import { IErrorWarning } from "../../../store/types/misc";
import { FaTimes } from "react-icons/fa";

type ICreateOppotunityType = {
  visible: boolean;
  onClose: () => void;
};

const CreateOppotunityType: React.FC<ICreateOppotunityType> = ({
  visible,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ opportunity }) => opportunity.loading);
  const [data, dataSet] = React.useState<{ name: string }>({ name: "" });
  const [err, errSet] = React.useState<{ name: IErrorWarning }>({ name: "" });

  return (
    <Modal open={visible}>
      <div className="flex w-full items-center justify-end">
        <div className="">
          <FaTimes
            onClick={onClose}
            size={24}
            color="#9CA3AF"
            className="cursor-pointer"
          />
        </div>
      </div>
      <div className="my-3">
        <TextInput
          type="text"
          name="name"
          label="Name"
          placeholder="Enter name"
          value={data.name}
          status={err.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            dataSet((prev) => ({ ...prev, [name]: value }));
            errSet((prev) => ({ ...prev, [name]: "" }));
          }}
        />
      </div>

      <div className="wfull flex justify-center">
        <div className="w-[50%] ">
          <Button
            title="Submit"
            type="submit"
            loading={loading}
            onClick={async () => {
              if (!data.name) {
                errSet((prev) => ({ ...prev, name: "warning" }));
                return;
              }
              const response = await create_type(data, dispatch);
              if (response) onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateOppotunityType;
