import React from "react";
import { Route, Routes } from "react-router-dom";
import { Theme } from "../../themes";

// pages
import DashBoard from "../dashboard/dashboard";
import { Company } from "../company";
import { Country, CountryDetails } from "../country";
import { User, UserDetails } from "../users";
import { Opportunity } from "../opportunity";
import { Training } from "../training";
import { CompaniesFile } from "../companiesFile";
import CompanyDetails from "../company/details";
import Services from "../services/dashboard";
import ShowRequest from "../request/show";
import ServiceRequest from "../request/dashboard";
// import OpportunityType from "../opportunity/misc/OpportunityType";

// blogs
import Blog from "../Blog/dashboard";
import BlogDetails from "../Blog/show";
import BlogCategory from "../Blog/categories";
import EditBlog from "../Blog/edit";
import CreateBlog from "../Blog/create";

import { Setting } from "../setting";
import ViewTraining from "../training/view/ViewTraining";
import ViewService from "../services/view";
import ViewOpportunityDetail from "../opportunity/show/ViewOpportunityDetail";

const Layout = () => {
  return (
    <Theme>
      <Routes>
        {/* requests and services */}
        <Route path="/request/:id" element={<ShowRequest />} />
        <Route path="/requests" element={<ServiceRequest />} />

        {/* services */}
        <Route path="/services/:id" element={<ViewService />} />
        <Route path="/services" element={<Services />} />

        {/* Opportunity */}
        <Route path="/opportunity" element={<Opportunity />} />
        <Route path="/opportunity/:id" element={<ViewOpportunityDetail />} />

        {/* Tranings */}
        <Route path="/training/:id" element={<ViewTraining />} />
        <Route path="/trainings" element={<Training />} />

        {/* Blogs */}
        <Route path="/create-blog" element={<CreateBlog />} />
        <Route path="/edit-blog/:id" element={<EditBlog />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog-categries" element={<BlogCategory />} />

        {/* users */}
        <Route path="/user/:email" element={<UserDetails />} />
        <Route path="/users" element={<User />} />

        {/* Companies */}
        <Route path="/company/details/:id" element={<CompanyDetails />} />
        <Route path="/companies" element={<Company />} />
        <Route path="/companies-files" element={<CompaniesFile />} />

        {/* location */}
        <Route path="/countries" element={<Country />} />
        <Route path="/country/:id" element={<CountryDetails />} />

        {/* Profile */}
        <Route path="/profile" element={<Setting />} />

        {/* Home */}
        <Route path="/" element={<DashBoard />} />
      </Routes>
    </Theme>
  );
};

export default Layout;
