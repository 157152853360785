import React from "react";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import { Modal } from "antd";
import Upload from "../../../component/modals/upload";
import Button from "../../../component/element/forms/Button";
import TextInput from "../../../component/element/forms/TextInput";
import SelectDate from "../../../component/element/forms/SelectDate";
// import SelectFile from "../../../component/element/forms/SelectFile";

// constants and icon
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import {
  IUploadErrorDocument,
  IUploadDocument as IUploadNewDocument,
} from "../../../store/types/request";
import { useAppSelector } from "../../../store";

type IUploadDocument = {
  show: boolean;
  onCloseView: (data: IUploadNewDocument) => void;
};

export const UploadDocument: React.FC<IUploadDocument> = ({
  show,
  onCloseView,
}) => {
  const { id } = useParams();
  const loading = useAppSelector(({ request }) => request.loading);
  const { file_loader, uploadFile } = useUploadFiles();
  const [data, dataSet] = React.useState<IUploadNewDocument>({
    id: "",
    issuing_body: "",
    issuing_date: "",
    expiring_date: "",
    path: "",
  });
  const [error, errorSet] = React.useState<IUploadErrorDocument>({
    id: "",
    issuing_body: "",
    issuing_date: "",
    expiring_date: "",
    path: "",
  });

  const onSubmit = async () => {
    let err = false;
    if (!data.id) {
      err = true;
      errorSet((prev) => ({ ...prev, id: "warning" }));
    }
    if (!data.expiring_date) {
      err = true;
      errorSet((prev) => ({ ...prev, expiring_date: "warning" }));
    }
    if (!data.issuing_body) {
      err = true;
      errorSet((prev) => ({ ...prev, issuing_body: "warning" }));
    }
    if (!data.issuing_date) {
      err = true;
      errorSet((prev) => ({ ...prev, issuing_date: "warning" }));
    }
    if (!data.path) {
      err = true;
      errorSet((prev) => ({ ...prev, path: "warning" }));
    }
    if (err) return;
    onCloseView(data);
  };

  const onLoad = React.useCallback(() => {
    if (id) dataSet((prev) => ({ ...prev, id }));
  }, [id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <Modal
      open={show}
      closable={false}
      footer={false}
      onCancel={() => null}
      centered={true}
    >
      <div className="w-full">
        <div className="w-full flex justify-end items-center mb-3">
          <AiOutlineClose
            size={25}
            color="red"
            onClick={() => {
              const newData = {
                id: "",
                issuing_body: "",
                issuing_date: "",
                expiring_date: "",
                path: "",
              };
              onCloseView(newData);
              dataSet(newData);
            }}
          />
        </div>

        <div className="my-2">
          <TextInput
            type="text"
            label="Issueing Body"
            placeholder="Enter Issueing Body"
            name="issuing_body"
            status={error?.issuing_body}
            value={data.issuing_body}
            onChange={onChange}
          />
        </div>

        <div className="my-2">
          <SelectDate
            label="Issueing Date"
            placeholder="Enter Issueing Date"
            // status={error.issuing_date}
            // value={data.issuing_date}
            onChange={(date) => {
              dataSet((prev) => ({ ...prev, issuing_date: date }));
              errorSet((prev) => ({ ...prev, issuing_date: "" }));
            }}
          />
        </div>

        <div className="my-2">
          <SelectDate
            label="Expire Date"
            placeholder="Enter Expire Date"
            // name="expiring_date"
            // value={data.expiring_date}
            onChange={(date) => {
              dataSet((prev) => ({ ...prev, expiring_date: date }));
              errorSet((prev) => ({ ...prev, expiring_date: "" }));
            }}
          />
        </div>

        <div className="my-2">
          {/* <SelectFile
              label="Upload File"
              placeholder="Upload your file"
              name="path"
              onChange={(res) => {
                if (res.files[0]) {
                  dataSet((prev) => ({ ...prev, path: res.files[0] }));
                  errorSet((prev) => ({ ...prev, path: "" }));
                }
              }}
            /> */}

          <Upload
            title="Upload opportunity File"
            name="opportunity_file"
            loading={file_loader}
            status={error.path}
            completed={data.path ? true : false}
            onChange={async (res) => {
              const avatar = await uploadFile(res);
              if (typeof avatar === "string") {
                dataSet((prev) => ({ ...prev, path: avatar }));
                errorSet((prev) => ({ ...prev, path: "" }));
              }
            }}
          />
        </div>

        <div className="my-4">
          <Button
            title="Submit"
            type="button"
            onClick={onSubmit}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};
