import axiosInstance from "../../utility/axios";

// state
import { openNotification } from "../../modules";
import {
  setUpload,
  setOpportunities,
  setOpportunityTypes,
  createOpportunity as dispatchNewOpportuity,
  deleteOpportunity,
  setLoading,
  editOpportunity,
  setTotal,
  createOpportunityType,
  deleteType,
  setOpportunity,
} from "../../store/slices/opportunity";
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";
import {
  ICreateOpportunity,
  OpportunityTypeCreate,
} from "../../store/types/opportunity";

export const get_opportunities = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-opportunities");

    const { data } = response?.data;
    dispatch(setOpportunities(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoading());
};

export const get_opportunity = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`v1/get-opportunity/${id}`);

    const { data } = response?.data;
    dispatch(setOpportunity(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoading());
};

export const create_opportunity = async (
  formData: ICreateOpportunity,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      "/v1/create-opportunities",
      formData
    );

    const { message, data } = response?.data;

    openNotification({
      type: "success",
      title: "Success",
      description: message,
    });
    dispatch(dispatchNewOpportuity(data));
    dispatch(setLoading());
    return true;
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        type: "error",
        title: "Error",
        description: message,
      });
    }
    dispatch(setLoading());
    return false;
  }
};

export const edit_opportunity = async (
  body: ICreateOpportunity,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch("/edit-opportunities", body);

    const { message, data } = response?.data;

    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
    dispatch(editOpportunity(data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data.message,
      });
    }
  }
  dispatch(setLoading());
};

export const upload = async (formData: any, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/upload", formData);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
      const { data } = response.data;
      dispatch(setUpload(data));
    }
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data.message,
      });
    }
  }
  dispatch(setLoading());
};

export const delete_opportunity = async (id: string, dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.delete(`/v1/delete-opportunity/${id}`);

    const { message } = response?.data;

    openNotification({
      type: "success",
      title: "Success",
      description: message,
    });
    dispatch(deleteOpportunity(id));
  } catch (err: any) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Error loading page",
        description: err?.response?.data?.message,
      });
    }
  }
};

/**
 * Oppportunity type
 */

export const get_opportunities_type = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-opportunity_types");

    const { data } = response.data;
    dispatch(setOpportunityTypes(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const create_type = async (
  form: OpportunityTypeCreate,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      "/v1/create-opportunity_types",
      form
    );

    const { message, data } = response.data;
    openNotification({
      type: "success",
      title: "Success",
      description: message,
    });
    dispatch(createOpportunityType(data));
    dispatch(setLoading());
    return true;
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        type: "error",
        title: "System Error",
        description: message,
      });
    }
    dispatch(setLoading());
    return false;
  }
};

export const delete_type = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(
      `/v1/delete-opportunity-type/${id}`
    );

    const { message, data } = response?.data;
    dispatch(deleteType(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        type: "error",
        title: "Error",
        description: message,
      });
    }
  }
  dispatch(setLoading());
};

// export const editOpportunity = async (data, dispatch) => {
//   dispatch(setOpportunityLoading());
//   try {
//     const response = await axiosInstance.put(`/edit-opportunities`, data);

//     openNotification({
//       type: "success",
//       title: "Successful!",
//       description: response?.data?.message,
//     });
//   } catch (err) {
//     const { data } = err.response;
//     openNotification({
//       type: "error",
//       title: "Incorrect Credentials",
//       description: data.message,
//     });
//   }
//   dispatch(disableOpportunityLoading());
// };

export const get_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-total-opportunity");

    const { data } = response.data;
    dispatch(setTotal(data));
  } catch (err: any) {
    if (isAxiosError(err)) console.log("first");
  }
  dispatch(setLoading());
};
