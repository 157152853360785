import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ITraining,
  ITrainingResponse,
  ITrainingState,
} from "../types/training";

const initialState: ITrainingState = {
  total: 0,
  month_total: 0,
  trainings: [],
  training: null,
  last_page: 1,
  current_page: 1,
  loading: false,
};

export const training = createSlice({
  name: "training",
  initialState,
  reducers: {
    setTrainings: (state, action: PayloadAction<ITrainingResponse>) => {
      state.trainings = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
    },
    setTraining: (state, action: PayloadAction<ITraining>) => {
      state.training = action.payload;
    },
    createTraining: (state, action: PayloadAction<ITraining>) => {
      state.trainings = [action.payload, ...state.trainings];
    },
    editTraining: (state, action: PayloadAction<ITraining>) => {
      state.trainings = state.trainings.map((item) => {
        if (action.payload.id === item.id) return action.payload;
        return item;
      });
    },
    setSearch: (state, action) => {
      state.trainings = action.payload.data;
    },
    setTrainingLoading: (state) => {
      state.loading = true;
    },
    disableTrainingLoading: (state) => {
      state.loading = false;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setMonthTotal: (state, action: PayloadAction<number>) => {
      state.month_total = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  createTraining,
  setTrainingLoading,
  disableTrainingLoading,
  setTrainings,
  setTraining,
  editTraining,
  setTotal,
  setMonthTotal,
  setLoading,
} = training.actions;

export default training.reducer;
