import React from "react";
import { ISimpleData } from "../../../store/types/misc";
import TextInput from "../../../component/element/forms/TextInput";
import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store";
import { edit_category } from "../../../services/apis/blog";

type IEditCategory = {
  data: ISimpleData;
  show: boolean;
  onModal: () => void;
};

const EditCategory: React.FC<IEditCategory> = ({ show, data, onModal }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ blog }) => blog.loading);
  const [form, formSet] = React.useState<ISimpleData>({
    name: "",
    id: "",
  });

  const onLoad = React.useCallback(() => {
    if (data.name && data.id) {
      const { name, id } = data;
      formSet({ id, name });
    }
  }, [data]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      open={show}
      centered={true}
      confirmLoading={loading}
      onCancel={onModal}
      onOk={() => edit_category(form, dispatch)}
    >
      <TextInput
        label="Category Name"
        name="name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          formSet((prev) => ({ ...prev, name: e.target.value }))
        }
        placeholder="Enter Category Name"
        type="text"
        value={form.name}
      />
    </Modal>
  );
};

export default EditCategory;
