import React from "react";
import { get_profile } from "../../../services/apis/setting";
import { useAppDispatch, useAppSelector } from "../../../store";
import { format_date_time } from "../../../services/convert/date";
import user_img from "../../../assets/images/user/user.jpg";

type IProfile = {};

const Profile: React.FC<IProfile> = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(({ setting }) => setting.profile);
  // const loading = useAppSelector(({ setting }) => setting.loading);
  // console.log(loading);

  const onLoad = React.useCallback(() => {
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full h-full flex items-center py-[40px] px-[10px]">
      <div className="mr-4">
        <img
          src={profile?.profile?.avatar || user_img}
          alt={profile?.profile?.name}
          className="w-[150px] h-[150px] rounded-full"
        />
      </div>
      <div>
        <p>Full Name: {profile?.profile?.name}</p>
        <p>Email: {profile?.email}</p>
        <p>User Name: {profile?.profile?.username}</p>
        <p>
          {`Last Updated:
          ${
            profile?.profile?.updated_at
              ? format_date_time(profile?.profile?.updated_at)
              : ""
          }`}
        </p>
      </div>
    </div>
  );
};

export default Profile;
