import axiosInstance from "../../utility/axios";

// notification
import { openNotification } from "../../modules";

// state and dispatch
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";
import { setProfile, setLoading, setAdmins } from "../../store/slices/setting";
import {
  IAdminChangePassword,
  IAdminVerify,
  IUpdatePassword,
} from "../../store/types/setting";
import { logout } from "./auth";

export const get_profile = async (
  dispatch: AppDispatch,
  page: string = "1"
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-profile?page=${page}`);

    dispatch(setProfile(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoading());
};

export const update_password = async (
  dispatch: AppDispatch,
  data: IUpdatePassword
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/change-password", data);
    openNotification({
      title: response.data?.message,
      description: "Password has been changed",
      type: "success",
    });
    await logout();
    get_profile(dispatch);
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoading());
};

/**
 * settings
 */

export const get_admins = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("get-admins");

    dispatch(setAdmins(response.data?.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // logger
    }
  }
};

export const update_verified = async (
  dispatch: AppDispatch,
  data: IAdminVerify
) => {
  try {
    const response = await axiosInstance.patch("/update-admin-verify", data);
    get_admins(dispatch);
    openNotification({
      type: "success",
      description: "Verification has been updated",
      title: response.data?.message,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      // logger
    }
  }
};

export const update_admin_password = async (
  dispatch: AppDispatch,
  data: IAdminChangePassword
) => {
  try {
    const response = await axiosInstance.patch("/update-admin-password", data);

    openNotification({
      type: "success",
      description: "Verification has been updated",
      title: response.data?.message,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      // logger
    }
  }
};
