import React from "react";

// component
import Button from "../form/Button";

// constants
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

type IPaginate = {
  current_page: number;
  last_page: number;
  onNext: (page: number) => void;
  onPrev: (page: number) => void;
};

const Paginate: React.FC<IPaginate> = ({
  current_page,
  last_page,
  onNext,
  onPrev,
}) => {
  return (
    <div className="flex w-full items-center justify-end py-[20px]">
      {current_page !== last_page ? (
        <>
          <Button
            title="Previous"
            children={<FaArrowLeft className="mr-1" />}
            classname=""
            onClick={() =>
              current_page > 1 || current_page <= last_page
                ? onPrev(current_page - 1)
                : null
            }
          />

          <span style={{ paddingLeft: 10, paddingRight: 10 }}>
            {current_page} | {last_page}
          </span>

          <Button
            title="Next"
            children={<FaArrowRight className="mr-1" />}
            classname=""
            onClick={() =>
              current_page < last_page && last_page > current_page
                ? onPrev(current_page - 1)
                : null
            }
          />
        </>
      ) : null}
    </div>
  );
};

export default Paginate;
