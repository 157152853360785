import React from "react";
import EntryRoute from "./router";
import { store } from "./store/store";
import { Provider } from "react-redux";
import "./index.css";
import "./assets/sass/index.scss";

const App = () => {
  return (
    <Provider store={store}>
      <EntryRoute />
    </Provider>
  );
};

export default App;
