/* eslint-disable no-unused-vars */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReportStatus, IResponseRequests } from "../types/request";
import { IBarData, ISimpleData } from "../types/misc";

const initialState: IReportStatus = {
  requests: [],
  search: [],
  loading: false,
  request: null,
  total: 0,
  last_page: 1,
  current_page: 1,
  update: false,
  bar: [],
  statuses: [],
};

export const request = createSlice({
  name: "request",
  initialState,
  reducers: {
    setServiceRequests: (state, action: PayloadAction<IResponseRequests>) => {
      state.requests = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
    },
    setServiceRequest: (state, action) => {
      state.request = action.payload;
    },
    setRequestStatus: (state, action: PayloadAction<ISimpleData[]>) => {
      state.statuses = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSearch: (state, action) => {
      // state.serviceRequests = action.payload.data;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setBarGraph: (state, action: PayloadAction<IBarData[]>) => {
      state.bar = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setServiceRequestLoading: (state) => {
      state.loading = true;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setLoading,
  setRequestStatus,
  setServiceRequest,
  setServiceRequestLoading,
  setUpdate,
  setTotal,
  setBarGraph,
  setServiceRequests,
} = request.actions;

export default request.reducer;
