import React from "react";

type IModal = {
  open: boolean;
  children: React.ReactNode;
};

const Modal: React.FC<IModal> = ({ open, children }) => {
  return (
    <div
      className={
        open
          ? "bg-[#ffffff70] absolute top-0 right-9 w-[100vw] h-[100vh] flex items-center justify-end z-[2]"
          : "hidden"
      }
    >
      <div className="box min-h-auto w-[500px] bg-primary-200 p-2 rounded-2xl overflow-y-auto overflow-x-hidden">
        <div className="bg-white p-4 rounded-lg">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
