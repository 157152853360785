import React from "react";
import { IEditService, IEditServiceError } from "../../../store/types/service";
import { useAppSelector } from "../../../store";

const useEditService = () => {
  const service = useAppSelector(({ service }) => service.service);
  const [data, dataSet] = React.useState<IEditService>({
    name: "",
    description: "",
    image: "",
    packages: [],
    id: "",
  });
  const [err, errSet] = React.useState<IEditServiceError>({
    description: "",
    id: "",
    name: "",
    image: "",
    packages: "",
  });

  const onLoad = React.useCallback(() => {
    if (service)
      dataSet((prev) => ({
        ...prev,
        image: service.image,
        id: service.id,
        name: service.name,
        description: service.description,
        packages: [],
      }));
  }, [service]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return { data, err, dataSet, errSet };
};

export default useEditService;
