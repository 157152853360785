import React from "react";

// components
import { Switch } from "antd";

// state
import { useAppDispatch, useAppSelector } from "../../../store";
import { useParams } from "react-router-dom";
import { get_opportunity } from "../../../services/apis/opportunity";

const ViewOpportunityDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const opportunity = useAppSelector(
    ({ opportunity }) => opportunity.opportunity
  );

  const onLoad = React.useCallback(() => {
    if (id) get_opportunity(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full grid grid-rows-[50%,1fr] gid-cols-1 justify-items-center h-full px-4 py-2 bg-[white]">
      <img
        src={opportunity?.opportunity_image}
        alt={opportunity?.opportunity_name}
        className="w-[22rem] h-[20rem] rounded-lg shadow-md"
      />
      <div>
        <h2>{opportunity?.opportunity_name}</h2>

        <div className="w-full flex items-center">
          <h1 className="text-[25px] mr-2">Status:</h1>
          <Switch
            checked={opportunity?.status === "1" ? true : false}
            onChange={() => null}
          />
        </div>

        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: opportunity?.opportunity_description || "",
          }}
        />
      </div>

      {/* <div className="w-[50%] border-[blue] border-2 h-full ml-2">
        <object
          width="100%"
          height="50%"
          data={opportunity?.opportunity_file}
          type="application/pdf"
        >
          No File for this Opportunity
        </object>
      </div> */}
    </div>
  );
};

export default ViewOpportunityDetail;
