import React from "react";

export type IButton = {
  type?: "button" | "submit";
  title: string;
  onClick: () => void;
  classname?: string;
  children?: React.ReactNode;
};

const Button: React.FC<IButton> = ({
  type = "button",
  title,
  classname,
  onClick,
  children,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`border-none py-2 font-bold flex w-full items-center justify-center rounded-sm ${classname}`}
    >
      {children} {title}
    </button>
  );
};

export default Button;
