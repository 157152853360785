import React from "react";

// component
import { Skeleton } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_total } from "../../../services/apis/training";

const TotalTraining = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ training }) => training.loading);
  const training = useAppSelector(({ training }) => training.total);

  const onLoad = React.useCallback(() => {
    get_total(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full h-[120px] shadow-sm rounded-2xl p-4 flex justify-between bg-white">
      <h5 className="text-lg font-medium">Training</h5>

      <div className="text-right">
        {loading ? (
          <Skeleton.Button active={true} block />
        ) : (
          <h5 className="text-4xl font-bold">{training}</h5>
        )}
      </div>
    </div>
  );
};

export default TotalTraining;
