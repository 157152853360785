import React from "react";
import useCreateCompany from "../hooks/useCreateCompany";

// components
import { Modal } from "antd";
import TextInput from "../../../component/element/forms/TextInput";
import SelectOption from "../../../component/element/forms/SelectOption";

// state
import {
  createCompany,
  rc_number_exists,
} from "../../../services/apis/company";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getAllCountry, getState } from "../../../services/apis/country";

type ICreateCompany = {
  visible: boolean;
  onHandler: () => void;
};

const CreateCompany: React.FC<ICreateCompany> = ({ visible, onHandler }) => {
  const dispatch = useAppDispatch();

  const states = useAppSelector(({ country }) => country.states);
  const countries = useAppSelector(({ country }) => country.all_countries);

  const { data, error, onChangeText, onValidate, onSelect, onResetData } =
    useCreateCompany();

  const handleOk = () => {
    const validate = onValidate();
    if (!validate) {
      return;
    }

    createCompany(data, dispatch);
    onHandler?.();
  };

  const onLoad = React.useCallback(() => {
    getAllCountry(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      title="Register Company"
      open={visible}
      onOk={handleOk}
      onCancel={onHandler}
      width="70%"
      centered={true}
    >
      <div className="w-full flex items-center justify-between mt-2">
        <div className="w-[49%]">
          <TextInput
            label="RC NUmber"
            name="rc_number"
            onChange={onChangeText}
            placeholder="RC Number"
            type="text"
            value={data.rc_number}
            status={error.rc_number}
            onBlur={async () => {
              const response = await rc_number_exists(data.rc_number);
              if (response) {
                onHandler();
                onResetData();
              }
            }}
          />
        </div>

        <div className="w-[49%]">
          <TextInput
            label="Company Email"
            name="company_email"
            onChange={onChangeText}
            placeholder="Company Email"
            type="text"
            value={data.company_email}
            status={error.company_email}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-between mt-2">
        <div className="w-[49%]">
          <TextInput
            label="Company Name"
            name="company_name"
            onChange={onChangeText}
            placeholder="Company Name"
            type="text"
            value={data.company_name}
            status={error.company_name}
          />
        </div>

        <div className="w-[49%]">
          <TextInput
            label="Company Phone"
            name="company_phone"
            onChange={onChangeText}
            placeholder="Company Phone"
            type="text"
            value={data.company_phone}
            status={error.company_phone}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-between mt-2">
        <div className="w-[49%]">
          <TextInput
            label="Company Address"
            name="company_address"
            onChange={onChangeText}
            placeholder="Company Address"
            type="text"
            value={data.company_address}
            status={error.company_address}
          />
        </div>

        <div className="w-[49%]">
          <TextInput
            label="Company Website"
            name="company_website"
            onChange={onChangeText}
            placeholder="Company Website"
            type="text"
            value={data.company_website}
            status={error.company_website}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-between mt-2">
        <div className="w-[49%]">
          <SelectOption
            items={countries}
            label="Country"
            onChange={(res) => {
              onSelect("company_country", res);
              getState(res, dispatch);
            }}
            placeholder="Select Country"
            value={data.company_country}
          />
        </div>

        <div className="w-[49%]">
          <SelectOption
            items={states}
            label="State"
            onChange={(res) => onSelect("company_state", res)}
            placeholder="Select State"
            value={data.company_state}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateCompany;
