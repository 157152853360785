// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import React from "react";

// components
import { ResponsiveBar } from "@nivo/bar";

// store and request
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_all_request_histogram } from "../../../services/apis/requests";

const BarChart: React.FC = () => {
  const dispatch = useAppDispatch();
  const chart = useAppSelector(({ request }) => request.bar);

  const onLoad = React.useCallback(() => {
    get_all_request_histogram(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <ResponsiveBar
      data={chart}
      keys={["y"]}
      indexBy="x"
      margin={{ top: 10, right: 10, bottom: 30, left: 50 }}
      padding={0.4}
      valueScale={{ type: "linear" }}
      colors="#3182CE"
      animate={true}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "request",
        legendPosition: "middle",
        legendOffset: -40,
      }}
    />
  );
};

export default BarChart;
