export type IPieChartObject = {
  id: string;
  label: string;
  value: number;
  color: string;
};

const color: string[] = [
  "hsl(90, 70%, 50%)",
  "hsl(56, 70%, 50%)",
  "hsl(103, 70%, 50%)",
];

type IPirChartObject = (nam: string, value: number) => IPieChartObject;
export const pie_data_generator: IPirChartObject = (name, value) => {
  return {
    color: color[Math.floor(Math.random() * color.length)],
    id: name,
    label: name,
    value,
  };
};
