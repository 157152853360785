import React from "react";

// state
import { ITrainingCreate, ITrainingError } from "../../../store/types/training";

const useCreateTraining = () => {
  const [data, dataSet] = React.useState<ITrainingCreate>({
    name: "",
    body: "",
    address: "",
    link: "",
    date: "",
    duration: "",
    amount: "",
    image: "",
    state_id: "",
    country_id: "",
  });

  const [error, errorSet] = React.useState<ITrainingError>({
    name: "",
    body: "",
    state_id: "",
    address: "",
    link: "",
    amount: "",
    date: "",
    duration: "",
    image: "",
  });

  const onValidate = () => {
    let err;
    if (!data.name) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.body) {
      err = true;
      errorSet((prev) => ({ ...prev, body: "warning" }));
    }
    if (!data.state_id) {
      err = true;
      errorSet((prev) => ({ ...prev, state_id: "warning" }));
    }
    if (!data.amount) {
      err = true;
      errorSet((prev) => ({ ...prev, amount: "warning" }));
    }
    if (!data.date) {
      err = true;
      errorSet((prev) => ({ ...prev, date: "warning" }));
    }
    if (!data.duration) {
      err = true;
      errorSet((prev) => ({ ...prev, duration: "warning" }));
    }
    if (!data.image) {
      err = true;
      errorSet((prev) => ({ ...prev, image: "warning" }));
    }
    if (err) return false;
    return true;
  };

  return {
    data,
    error,
    dataSet,
    errorSet,
    onValidate,
  };
};

export default useCreateTraining;
