import axiosInstance from "../../utility/axios";

// state
import { openNotification } from "../../modules";
import {
  deleteService,
  setLoading,
  createService,
  setServices,
  setTotal,
  editService,
  setService,
} from "../../store/slices/services";
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";
import {
  ICreateService,
  IEditService,
  // IUpdateStatus,
} from "../../store/types/service";

export const get_services = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-services"); // show-file-logs

    dispatch(setServices(response?.data?.data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const get_service = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/v1/get-service/${id}`);

    const { data } = response?.data;
    dispatch(setService(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setLoading());
};

export const create_service = async (
  form: ICreateService,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/v1/create-services", form);

    const { message, data } = response.data;

    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
    dispatch(createService(data));
    dispatch(setLoading());
    return true;
  } catch (err: any) {
    if (isAxiosError(err))
      openNotification({
        type: "error",
        title: "Error",
        description: err.response?.data.message,
      });
    dispatch(setLoading());
    return false;
  }
};

export const edit_service = async (
  form: IEditService,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch(`/v1/edit-service`, form);

    const { data, message } = response.data;
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
    dispatch(editService(data));
    dispatch(setService(data));
    dispatch(setLoading());
    return true;
  } catch (err) {
    if (isAxiosError(err))
      openNotification({
        type: "error",
        title: "Error",
        description: err?.response?.data?.message,
      });
    dispatch(setLoading());
    return false;
  }
};

export const delete_service = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(`/v1/delete-service/${id}`);

    const { message, data } = response.data;
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
    dispatch(deleteService(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Error",
        description: err.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

// ================================================================================

export const get_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance("/v1/get-total-service");

    const { data } = response.data;
    dispatch(setTotal(data));
  } catch (error) {
    if (isAxiosError(error)) console.log(error);
  }
  dispatch(setLoading());
};

export const update_status = async (dispatch: AppDispatch, id: string) => {
  try {
    const response = await axiosInstance.patch(`/v1/update-status/${id}`);

    const { data, message } = response.data;
    dispatch(editService(data));
    dispatch(setService(data));
    openNotification({
      type: "success",
      title: "Success",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        type: "error",
        title: "Error",
        description: message,
      });
    }
  }
};

export const delete_package = async (dispatch: AppDispatch, id: string) => {
  try {
    const response = await axiosInstance.delete(`v1/delete-package/${id}`);

    const { message, data } = response.data;
    dispatch(editService(response?.data?.data));
    dispatch(setService(data));
    openNotification({
      type: "success",
      title: "Success",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        type: "error",
        title: "You cannot delete this package",
        description: message,
      });
    }
  }
};
