import React from "react";

// constants and icons
import { Spin } from "antd";
import { ImFolderUpload } from "react-icons/im";
import { BsFillCloudCheckFill } from "react-icons/bs";

// types
import { IErrorWarning } from "../../../store/types/misc";

type IUpload = {
  title: string;
  name: string;
  onChange: (file: File) => void;
  required?: boolean;
  completed: boolean;
  status: IErrorWarning;
  loading?: boolean;
};

const Upload: React.FC<IUpload> = ({
  title,
  name,
  onChange,
  required,
  completed,
  status,
  loading,
}) => {
  return (
    <div
      onClick={() => document?.getElementById(name)?.click()}
      className="w-full border flex flex-col justify-center items-center cursor-pointer py-5 rounded-xl"
    >
      <label className="block">
        {title}
        {required && <span className="text-[red]"> *</span>}
      </label>
      <ImFolderUpload
        color={status ? "red" : completed ? "green" : "grey"}
        size={80}
        className="block"
      />
      {loading && <Spin />}
      {completed && <BsFillCloudCheckFill color="green" size={20} />}
      <input
        type="file"
        name={name}
        id={name}
        value=""
        className="outline-none"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { files } = e.currentTarget;
          if (files?.length) onChange(files[0]);
        }}
        hidden
      />
    </div>
  );
};

export default Upload;
