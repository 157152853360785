import React from "react";

// request
import axiosUpload from "../../utility/axiosUpload";

const useUploadFiles = () => {
  const [img_loader, img_loaderSet] = React.useState(false);
  const [file_loader, file_loaderSet] = React.useState(false);

  const uploadFile = async (file: File | null) => {
    file_loaderSet(true);
    let path;
    try {
      const formData = new FormData();
      if (file !== null) formData.append("file", file);
      // if (file !== null) formData.append("file", file, { type: file?.type });

      const config = {
        transformRequest: (formData: FormData) => formData,
      };
      const response = await axiosUpload.post("/upload-file", formData, config);
      path = response.data;
    } catch (error) {
      path = null;
    }
    file_loaderSet(false);
    return path;
  };

  const uploadAvatar = async (file: File | null) => {
    img_loaderSet(true);
    let path: string | null;
    try {
      const formData = new FormData();
      if (file !== null && file?.type) formData.append("file", file);
      // formData.append("file", file, { type: file?.type });

      const config = {
        transformRequest: (formData: FormData) => formData,
      };
      const response = await axiosUpload.post("/upload", formData, config);
      path = response.data;
    } catch (error) {
      path = null;
    }
    img_loaderSet(false);
    return path;
  };

  return { img_loader, file_loader, uploadFile, uploadAvatar };
};

export default useUploadFiles;
