import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IGetAllOpportunity,
  IOpportunity,
  IOpportunityState,
  IOpportunityTypes,
} from "../types/opportunity";

const initialState: IOpportunityState = {
  opportunities: [],
  opportunity: null,
  opportunity_types: [],
  search: [],
  upload: {},
  total: 0,
  last_page: 1,
  current_page: 1,
  loading: false,
  update: false,
};

export const opportunity = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    setOpportunities: (state, action: PayloadAction<IGetAllOpportunity>) => {
      state.opportunities = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
    },
    setOpportunity: (state, action: PayloadAction<IOpportunity>) => {
      state.opportunity = action.payload;
    },
    createOpportunity: (state, action: PayloadAction<IOpportunity>) => {
      state.opportunities = [action.payload, ...state.opportunities];
    },
    editOpportunity: (state, action: PayloadAction<IOpportunity>) => {
      state.opportunities = state.opportunities.map((item) => {
        if (item.id === action.payload.id) {
          item = { ...action.payload };
        }
        return item;
      });
    },
    deleteOpportunity: (state, action: PayloadAction<string>) => {
      state.opportunities = state.opportunities.filter(
        (item) => item.id !== action.payload
      );
    },

    // =======================================================================================
    setOpportunityTypes: (
      state,
      action: PayloadAction<IOpportunityTypes[]>
    ) => {
      state.opportunity_types = action.payload;
    },
    createOpportunityType: (
      state,
      action: PayloadAction<IOpportunityTypes>
    ) => {
      state.opportunity_types = [action.payload, ...state.opportunity_types];
    },
    deleteType: (state, action: PayloadAction<string>) => {
      state.opportunity_types = state.opportunity_types.filter(
        (type) => type.id?.toString() !== action.payload?.toString()
      );
    },
    // ========================================================================================

    setUpload: (state, action) => {
      state.upload = action.payload;
    },
    setSearch: (state, action) => {
      state.opportunities = action.payload.data;
    },
    setOpportunityLoading: (state) => {
      state.loading = true;
    },
    disableOpportunityLoading: (state) => {
      state.loading = false;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  // ===============================
  setOpportunities,
  setOpportunity,
  // ===============================
  setOpportunityTypes,
  createOpportunityType,
  deleteType,
  // ===============================
  createOpportunity,
  editOpportunity,
  setUpload,
  deleteOpportunity,
  setOpportunityLoading,
  setSearch,
  disableOpportunityLoading,
  setTotal,
  setLoading,
  setUpdate,
} = opportunity.actions;

export default opportunity.reducer;
