import React from "react";

// components
import DataTable from "../../../component/element/table";
import Button from "../../../component/element/forms/Button";
import EditService from "../edit";
import { Switch } from "antd";

// dispatch and types
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  get_service,
  delete_package,
  update_status,
} from "../../../services/apis/services";
import { ColumnDef } from "@tanstack/react-table";
import { ISimpleData } from "../../../store/types/misc";

// constants
import { useParams } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";

const ViewService: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const service = useAppSelector(({ service }) => service.service);
  const [show, showSet] = React.useState<string>("");

  const onLoad = React.useCallback(() => {
    if (id) get_service(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  const colums: ColumnDef<ISimpleData>[] = [
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ getValue }) => {
        const name = getValue() as string;
        return name;
      },
    },
    {
      accessorKey: "id",
      header: "Action",
      cell: ({ getValue }) => {
        const id = getValue() as string;
        return (
          <MdOutlineDelete
            size={18}
            className="cursor-pointer"
            onClick={() => delete_package(dispatch, id)}
          />
        );
      },
    },
  ];

  return (
    <div className="grid gap-1 grid-cols-2 w-full">
      <div>
        <img
          src={service?.image}
          alt={service?.name}
          className="w-full border h-[40vh] overflow-hidden"
        />
        <p className="font-[700]">{service?.name}</p>
        <Switch
          checked={service?.status === "1" ? true : false}
          onClick={() => {
            if (id) update_status(dispatch, id);
          }}
        />
        <div dangerouslySetInnerHTML={{ __html: service?.description || "" }} />
      </div>
      <div className="grid gap-1 grid-cols-1 grid-rows-[4rem,1fr]">
        <div className="w-full flex items-center justify-end px-2">
          <div className="w-[35%] ">
            <Button
              title="Edit Service"
              type="button"
              onClick={() => {
                if (id) showSet(id);
              }}
            />
          </div>
        </div>
        <div className="">
          <DataTable columns={colums} data={service?.packages || []} />
        </div>
      </div>
      {show && (
        <EditService
          show={show ? true : false}
          id={show}
          onCancel={() => showSet("")}
        />
      )}
    </div>
  );
};

export default ViewService;
