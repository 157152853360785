import React from "react";
import useCreateTraining from "../hooks/useCreateTraining";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import { Modal } from "antd";
import Upload from "../../../component/modals/upload";
import TextBox from "../../../component/element/forms/TextBox";
import TextInput from "../../../component/element/forms/TextInput";
import SelectDate from "../../../component/element/forms/SelectDateTime";
import SelectOption from "../../../component/element/forms/SelectOption";

// state
import { create } from "../../../services/apis/training";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getCountry, getState } from "../../../services/apis/country";

type ICreateTraining = {
  show: boolean;
  onHandler: () => void;
};

const CreateTraining: React.FC<ICreateTraining> = ({ show, onHandler }) => {
  const dispatch = useAppDispatch();

  const states = useAppSelector(({ country }) => country.states);
  const countries = useAppSelector(({ country }) => country.countries);

  const { data, dataSet, errorSet, onValidate } = useCreateTraining();
  const { img_loader, uploadAvatar } = useUploadFiles();

  const handleOk = () => {
    const validate = onValidate();
    if (!validate) return;

    create(data, dispatch);
    onHandler();
  };

  const onLoad = React.useCallback(() => {
    getCountry(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      title="Add Training"
      open={show}
      onOk={handleOk}
      onCancel={onHandler}
      centered={true}
      width="60%"
    >
      <div className="my-3">
        <div className="my-2">
          <TextInput
            label="Title"
            name="name"
            placeholder="Title"
            value={data.name}
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value, name } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: "" }));
            }}
          />
        </div>

        <div className="my-2">
          <TextInput
            label="Address"
            name="address"
            placeholder="Address"
            value={data.address}
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value, name } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: "" }));
            }}
          />
        </div>

        <div className="my-2 flex w-full">
          <div className="w-[50%] mr-1">
            <TextInput
              label="Link"
              name="link"
              placeholder="Link"
              value={data.link}
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value, name } = e.target;
                dataSet((prev) => ({ ...prev, [name]: value }));
                errorSet((prev) => ({ ...prev, [name]: "" }));
              }}
            />
          </div>
          <div className="w-[50%] ml-1">
            <TextInput
              label="Amount"
              name="amount"
              placeholder="Amount"
              value={data.amount}
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value, name } = e.target;
                dataSet((prev) => ({ ...prev, [name]: value }));
                errorSet((prev) => ({ ...prev, [name]: "" }));
              }}
            />
          </div>
        </div>

        <div className="my-2 flex w-full items-center">
          <div className="w-[50%] mr-1">
            <TextInput
              label="Duration"
              name="duration"
              placeholder="Duration"
              value={data.duration}
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value, name } = e.target;
                dataSet((prev) => ({ ...prev, [name]: value }));
                errorSet((prev) => ({ ...prev, [name]: "" }));
              }}
            />
          </div>
          <div className="w-[50%] ml-1">
            <SelectDate
              label="Date"
              placeholder="Date"
              onChange={(e) => {
                dataSet((prev) => ({ ...prev, date: e }));
                errorSet((prev) => ({ ...prev, date: "" }));
              }}
            />
          </div>
        </div>

        <div className="my-2 flex w-full">
          <div className="w-[50%] mr-1">
            <SelectOption
              items={countries}
              label="Country"
              placeholder="Country"
              onChange={(e) => {
                dataSet((prev) => ({ ...prev, country_id: e }));
                errorSet((prev) => ({ ...prev, country_id: "" }));
                getState(e, dispatch);
              }}
            />
          </div>
          <div className="w-[50%] ml-1">
            <SelectOption
              items={states}
              label="State"
              placeholder="State"
              onChange={(e) => {
                dataSet((prev) => ({ ...prev, state_id: e }));
                errorSet((prev) => ({ ...prev, state_id: "" }));
              }}
            />
          </div>
        </div>

        <div>
          <TextBox
            name="body"
            placeholder="Description"
            value={data.body}
            onChange={(name, value) => {
              dataSet((prev) => ({ ...prev, body: value }));
              errorSet((prev) => ({ ...prev, body: "" }));
            }}
          />
        </div>

        <div className="my-2 flex w-full">
          <Upload
            title="Upload Image"
            name="image"
            loading={img_loader}
            status=""
            completed={data.image ? true : false}
            onChange={async (res) => {
              const avatar = await uploadAvatar(res);
              if (typeof avatar === "string") {
                dataSet((prev) => ({ ...prev, image: avatar }));
                errorSet((prev) => ({ ...prev, image: "" }));
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateTraining;
