import { configureStore } from "@reduxjs/toolkit";

// slices
import auth from "./slices/auth";
import country from "./slices/country";
import opportunity from "./slices/opportunity";
import training from "./slices/training";
import loading from "./slices/loading";
import company from "./slices/company";
import blog from "./slices/blog";
import user from "./slices/user";
import companiesFile from "./slices/companiesFile";
import service from "./slices/services";
import request from "./slices/request";
import setting from "./slices/setting";

export const store = configureStore({
  reducer: {
    opportunity,
    service,
    auth,
    loading,
    blog,
    request,
    companiesFile,
    training,
    country,
    company,
    user,
    setting,
  },
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
