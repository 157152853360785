import axiosInstance from "../../utility/axios";

// state
import { openNotification } from "../../modules";
import { setLoading, disableLoading } from "../../store/slices/loading";
import {
  disableCompaniesFileLoading,
  setCompaniesFileLoading,
  setCompaniesFile,
} from "../../store/slices/companiesFile";

export const getAllCompaniesFile = async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/company-files"); // show-file-logs
    if (response.data) {
      const { data } = response.data;
      dispatch(setCompaniesFile(data));
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const createCompaniesFile = async (formData, dispatch) => {
  dispatch(setCompaniesFileLoading());
  try {
    const response = await axiosInstance.post("/create-training", formData);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableCompaniesFileLoading());
};

export const editCompaniesFile = async (data, dispatch) => {
  dispatch(setCompaniesFileLoading());
  try {
    const response = await axiosInstance.put(`/edit-training`, data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableCompaniesFileLoading());
};
