import React from "react";

// components
import Request from "../misc/Request";
import Training from "../misc/Training";
import TotalServive from "../misc/TotalServive";
import TotalTraining from "../misc/TotalTraining";
import TotalCompanyUser from "../misc/TotalCompanyUser";
import TotalOpportunity from "../misc/TotalOpportunity";
import PieChart from "../../../component/ui/charts/PieChart";
import BarChart from "../../../component/ui/charts/BarChart";

// state
import { useAppDispatch } from "../../../store";
import { getAuthUser } from "../../../services/apis/auth";

const DashBoard = () => {
  const dispatch = useAppDispatch();
  // const loading = useAppSelector(({ loading }) => loading.loading);

  const onLoad = React.useCallback(async () => {
    getAuthUser(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-6 mb-4">
        <TotalServive />

        <TotalTraining />

        <TotalOpportunity />

        <TotalCompanyUser />
      </div>

      <div className="w-full flex justify-center items-center h-[50vh] mb-5 p-4 rounded-2xl bg-white shadow-md">
        <div className="w-[65%] h-[40vh] border p-2 mr-2">
          <BarChart />
        </div>
        <div className="w-[35%] h-[40vh] p-2">
          <PieChart />
        </div>
      </div>

      <div className="w-full flex my-3">
        <Request />
        <Training />
      </div>
    </div>
  );
};

export default DashBoard;
