import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IService, IServiceState, IServicesResponse } from "../types/service";

const initialState: IServiceState = {
  services: [],
  service: null,
  total: 0,
  loading: false,
  last_page: 1,
  current_page: 1,
};

export const service = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<IServicesResponse>) => {
      state.services = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
    },
    setService: (state, action: PayloadAction<IService>) => {
      state.service = action.payload;
    },
    createService: (state, action: PayloadAction<IService>) => {
      state.services = [action.payload, ...state.services];
    },
    editService: (state, action: PayloadAction<IService>) => {
      state.services = state.services.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setSearch: (state, action) => {
      state.services = action.payload.data;
    },
    deleteService: (state, action: PayloadAction<string>) => {
      state.services = state.services.filter(
        (item) => item.id !== action.payload
      );
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setLoading,
  setUpdate,
  deleteService,
  setTotal,
  setServices,
  setService,
  createService,
  editService,
} = service.actions;

export default service.reducer;
