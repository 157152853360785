import React from "react";

// components
// import DataDisplay from "../../../component/dataDisplay";

// state and dispatch
import { get_user_logs } from "../../../services/apis/company";
import { useAppDispatch, useAppSelector } from "../../../store";

type IUserFileLog = {
  email: string;
};

const UserFileLog: React.FC<IUserFileLog> = ({ email }) => {
  const dispatch = useAppDispatch();
  const logs = useAppSelector(({ company }) => company.logs);
  // const loading = useAppSelector(({ company }) => company.loading);

  const onLoad = React.useCallback(() => {
    if (email) get_user_logs(email, dispatch);
  }, [dispatch, email]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  console.log(logs);

  return (
    <div className="w-[39%] flex justify-center items-start h-full border">
      <div>Company Details</div>
      <div></div>
    </div>
  );
};

export default UserFileLog;
