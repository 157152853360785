import axiosInstance from "../../utility/axios";

import { set_cookie, delete_cookie } from "../../utility/cookies";

// state
import { setLoading, disableLoading } from "../../store/slices/loading";
import { setUser } from "../../store/slices/auth";
import { openNotification } from "../../modules";

export const authUser = async (data, dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/login", data);
    if (response.data) {
      set_cookie("@serial", response?.data?.access_token);
      navigate("/");
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const getAuthUser = async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/user");
    dispatch(setUser(response.data));
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const logout = async () => {
  try {
    await axiosInstance.get("/logout");

    delete_cookie("@serial");
  } catch (error) {}
};
