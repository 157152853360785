import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { IEmployee } from "../../../store/types/employee";
import { get_profiles } from "../../../services/apis/user";
// import { get_profiles, setAdminCompany } from "../../../services/apis/user";
// import { FaEye, FaTrash } from "react-icons/fa";
import DataTable from "../../../component/element/table";
import { ColumnDef } from "@tanstack/react-table";
import { IUser } from "../../../store/types/user";
import { RiAdminFill, RiUser2Fill } from "react-icons/ri";

type ICompanyStaff = {
  branches: string[];
};

const CompanyStaff: React.FC<ICompanyStaff> = ({ branches }) => {
  const dispatch = useAppDispatch();
  const employees = useAppSelector(({ user }) => user.profiles);
  // const loading = useAppSelector(({ company }) => company.loading);

  const onLoad = React.useCallback(() => {
    if (branches?.length) {
      const query = `?branches=${branches.toString()}`;
      get_profiles(dispatch, query);
    }
  }, [branches, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: ColumnDef<IEmployee>[] = [
    {
      accessorKey: "name",
      header: () => <span className="flex justify-center">Name</span>,
      cell: ({ getValue }) => {
        const name = getValue() as string;
        return <span className="flex justify-center">{name}</span>;
      },
    },
    {
      accessorKey: "user",
      header: () => <span className="flex justify-center">Email</span>,
      cell: ({ getValue }) => {
        const user = getValue() as IUser;
        return <span className="flex justify-center">{user?.email}</span>;
      },
    },
    {
      accessorKey: "user",
      header: () => <span className="flex justify-center">Phone</span>,
      cell: ({ getValue }) => {
        const user = getValue() as IUser;
        return <span className="flex justify-center">{user?.email}</span>;
      },
    },
    {
      accessorKey: "user",
      header: () => <span className="flex justify-center">Role</span>,
      cell: ({ getValue }) => {
        const user = getValue() as IUser;
        return (
          <span className="flex justify-center">
            {user?.role === "1" ? (
              <RiAdminFill size={22} color="red" />
            ) : (
              <RiUser2Fill size={22} color="blue" />
            )}
          </span>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={employees} />;
};

export default CompanyStaff;
