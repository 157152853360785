import React from "react";

// components
import { Modal } from "antd";
import TextInput from "../../../component/element/forms/TextInput";

// dispatch and state
import { useAppDispatch, useAppSelector } from "../../../store";
import { create_category } from "../../../services/apis/blog";

type ICreateCategory = {
  show: boolean;
  onModal: () => void;
};

const CreateCategory: React.FC<ICreateCategory> = ({ show, onModal }) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(({ blog }) => blog.loading);

  const [data, dataSet] = React.useState<{ name: string }>({
    name: "",
  });

  return (
    <Modal
      open={show}
      centered={true}
      confirmLoading={loading}
      onCancel={onModal}
      onOk={() => create_category(data, dispatch)}
    >
      <TextInput
        label="Category Name"
        name="name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          dataSet({ name: e.target.value })
        }
        placeholder="Enter Category Name"
        type="text"
        value={data.name}
      />
    </Modal>
  );
};

export default CreateCategory;
