import React from "react";
import useDetails from "../hooks/useDetails";

// react router
import { useParams } from "react-router-dom";

// compoenents
import { Collapse } from "antd";

// state
import { useSelector } from "react-redux";

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const CountryDetails = () => {
  const { id } = useParams();
  const { onLoad } = useDetails();
  const state = useSelector(({ country }) => country?.states);

  React.useEffect(() => {
    onLoad(id);
  }, [id, onLoad]);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <div style={{ display: "block" }}>
        <h1>
          {state !== null && `${state?.country?.name} (${state?.country?.abb})`}
        </h1>
      </div>
      <div>
        <Collapse>
          {state !== null &&
            state.length &&
            state?.states.map((item, index) => (
              <Panel header={item?.name} key={index}>
                <p>{text}</p>
              </Panel>
            ))}
        </Collapse>
      </div>
    </div>
  );
};

export default CountryDetails;
