import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAdminUser,
  IAdminUserResponse,
  ISettingStatus,
} from "../types/setting";

const initialState: ISettingStatus = {
  profile: null,
  admins: [],
  current_page: 1,
  last_page: 1,
  loading: false,
};

export const setting = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IAdminUser>) => {
      state.profile = action.payload;
    },
    setAdmins: (state, action: PayloadAction<IAdminUserResponse>) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.admins = action.payload.data;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, setAdmins, setLoading } = setting.actions;

export default setting.reducer;
