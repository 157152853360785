import React from "react";

// components
import { CgMenuLeftAlt, CgMenuRight } from "react-icons/cg";

// react router navigation
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_profile } from "../../../services/apis/setting";

type INavbar = {
  collapse: boolean;
  onCollapse: () => void;
};

const Navbar: React.FC<INavbar> = ({ collapse, onCollapse }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(({ setting }) => setting.profile);

  const onLoad = React.useCallback(() => {
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <header className="w-full flex justify-between items-center text-gray bg-[white] px-4">
      <div className="cursor-pointer">
        {collapse ? (
          <CgMenuLeftAlt size={24} onClick={onCollapse} />
        ) : (
          <CgMenuRight size={24} onClick={onCollapse} />
        )}
      </div>

      <div className="flex gap-x-3 items-center">
        <img
          src={profile?.profile?.avatar}
          alt={profile?.profile?.name}
          className="w-[2.5rem] h-[2.5rem] rounded-full cursor-pointer border"
          onClick={() => navigate("/profile")}
        />

        <span className="cursor-pointer">Logout</span>
      </div>
    </header>
  );
};

export default Navbar;
