import React from "react";

type ITab = {
  value: string;
};

const Tab: React.FC<ITab> = ({ value }) => {
  return (
    <div className="px-[14px] py-[4px] bg-[#ADD8E6] mr-2 rounded-xl text-[blue]">
      {value}
    </div>
  );
};

export default Tab;
