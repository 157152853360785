import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from "axios";
import env from "../config/env";
import { get_cookie, delete_cookie } from "./cookies";

const headers = {};

const axiosInstance = axios.create({
  baseURL: env.url,
  headers,
});

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.headers) {
      const serial = get_cookie("@serial");
      if (serial) {
        config.headers.Authorization = `bearer ${serial}`;
      }
      config.headers.Accept = "application/json";
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) =>
    new Promise((resolve, _) => {
      resolve(response);
    }),
  (error) => {
    if (isAxiosError(error)) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        delete_cookie("@serial");
        window.location.href = "/login";
      }
    }
    return new Promise((_resolve, reject) => {
      reject(error);
    });
  }
);

export default axiosInstance;
