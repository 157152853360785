/* eslint-disable no-unused-vars */
import React from "react";

// components
import { Spin } from "antd";

// types
import { IErrorWarning } from "../../../store/types/misc";

type ITextInput = {
  label: string;
  name: string;
  value: string;
  type: "text" | "file" | "date" | "password";
  status?: IErrorWarning;
  placeholder: string;
  loading?: boolean;
  icon?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
};

const TextInput: React.FC<ITextInput> = ({
  label,
  name,
  value,
  type,
  status,
  placeholder,
  loading,
  icon,
  onChange,
  onBlur,
}) => {
  return (
    <div className="w-full">
      {label && <div className="w-full block mb-[2px] font-bold">{label}</div>}
      <div
        className={`flex w-full py-[8px] rounded-sm border bg-white hover:border-[#67C748] cursor-pointer ${
          status ? "border-[red]" : ""
        }`}
      >
        {icon && (
          <div className="border-r w-[40px] flex justify-center items-center">
            {icon}
          </div>
        )}
        <div className="flex-1 px-[6px]">
          <input
            name={name}
            value={value}
            type={type || "text"}
            placeholder={placeholder}
            onChange={onChange}
            className="w-full border-none outline-none focus:outline-none placeholder:text-[15px]"
            onBlur={onBlur}
          />
        </div>
        {loading && (
          <div className="w-[5%] flex justify-center items-center">
            <Spin size="small" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
