import React from "react";

// components

// state
import { get_statuses } from "../../../services/apis/requests";
import { useAppDispatch, useAppSelector } from "../../../store";
import SelectOption from "../../../component/element/forms/SelectOption";
import { ISimpleData } from "../../../store/types/misc";

type IRequestStep = {
  current: ISimpleData;
};

const RequestStep: React.FC<IRequestStep> = ({ current }) => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(({ request }) => request.statuses);
  const [data, dataSet] = React.useState<string>("");

  const onLoad = React.useCallback(() => {
    get_statuses(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="py-2 px-7 shadow-md rounded-sm">
      <div className="w-full flex justify-end">
        <div className="w-[20%]">
          <SelectOption
            items={statuses}
            label="Order Status"
            placeholder="Select here..."
            value={data}
            onChange={(res) => dataSet(res)}
          />
        </div>
      </div>
      <div className="flex w-full justify-between items-center mt-4">
        {statuses.map((status) => (
          <div className="w-full mx-1 text-center" key={status?.id}>
            <div className={`py-1 bg-primary-400 rounded-md`} />
            <span
              className={
                current?.id?.toString() === status.id
                  ? "text-primary-800"
                  : "text-gray"
              }
            >
              {status?.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RequestStep;
