import React from "react";

// components
// import TextInput from "../../../component/element/forms/TextInput";

// icons and constants
import { FaEye } from "react-icons/fa";

// state and dispatch
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_admins, update_verified } from "../../../services/apis/setting";
import { Switch, Table, TableProps } from "antd";
import { IAdminUser } from "../../../store/types/setting";
import { real_date_time } from "../../../services/convert/date";
import AdminUser from "./AdminUser";

const ListAdminUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ setting }) => setting.loading);
  const admins = useAppSelector(({ setting }) => setting.admins);

  const [data, dataSet] = React.useState<{
    show: boolean;
    data: null | IAdminUser;
  }>({
    data: null,
    show: false,
  });

  const columns: TableProps<IAdminUser>["columns"] = [
    {
      title: "Name",
      dataIndex: "profile",
      render: (data) => <p>{data?.name || ""}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (data) => <p>{data}</p>,
    },
    {
      title: "Username",
      dataIndex: "profile",
      render: (data) => <p>{data?.username}</p>,
    },
    {
      title: "Last Updated",
      dataIndex: "profile",
      render: (data) => (
        <p>{data?.updated_at ? real_date_time(data.updated_at) : ""}</p>
      ),
    },
    {
      title: "Active",
      dataIndex: "email_verified_at",
      render: (verified, data) => (
        <Switch
          checked={verified ? true : false}
          onChange={() => update_verified(dispatch, { email: data.email })}
        />
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (_, data) => (
        <FaEye
          size={20}
          className="cursor-pointer"
          onClick={() => dataSet({ show: true, data: data })}
        />
      ),
    },
  ];

  const onLoad = React.useCallback(() => {
    get_admins(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full h-full px-[10px]">
      <Table
        columns={columns}
        dataSource={admins}
        loading={loading}
        key="id"
        rowKey="id"
      />

      {data.show && data.data && (
        <AdminUser
          show={data.show}
          data={data.data}
          onCancel={() => dataSet({ data: null, show: false })}
        />
      )}
    </div>
  );
};

export default ListAdminUser;
