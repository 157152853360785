/* eslint-disable no-unused-vars */
import React from "react";

// components
import { DatePicker, Spin } from "antd";

type ISelectDate = {
  label: string;
  placeholder: string;
  loading?: boolean;
  icon?: React.ReactNode;
  onChange: (date_string: string) => void;
};

const SelectDate: React.FC<ISelectDate> = ({
  label,
  placeholder,
  loading,
  icon,
  onChange,
}) => {
  return (
    <div className="w-full">
      {label && <div className="w-full block mb-[2px] font-bold">{label}</div>}
      <div className="flex w-full py-[8px] rounded-sm border border-[#000000] hover:border-[#67C748] overflow-hidden">
        {icon && (
          <div className="border-r w-[40px] flex justify-center items-center">
            {icon}
          </div>
        )}
        <div className="flex-1 px-[6px]">
          <DatePicker
            onChange={(_date, date_string) => onChange(date_string)}
            placeholder={placeholder}
            // defaultValue={dayjs("2015-01-01", "YYYY-MM-DD")}
            style={{ border: "none", outline: "none" }}
            className="w-full placeholder:text-[15px]"
          />
        </div>

        {loading && (
          <div className="w-[5%] flex justify-center items-center">
            <Spin size="small" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectDate;
