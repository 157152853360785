import React from "react";

// components
import { Modal } from "antd";

// constants and icon
import { AiOutlineClose } from "react-icons/ai";

type IViewDocument = {
  title: string;
  path: string;
  visible: boolean;
  onCloseView: () => void;
};

const ViewDocument: React.FC<IViewDocument> = ({
  title,
  path,
  visible,
  onCloseView,
}) => {
  return (
    <div>
      <Modal
        open={visible}
        closable={false}
        footer={false}
        width="80%"
        centered
        onCancel={onCloseView}
      >
        <div className="w-full h-[90vh] overflow-hidden">
          <div className="w-full flex justify-end items-center mb-3">
            <AiOutlineClose
              size={25}
              color="red"
              onClick={() => onCloseView()}
              className="cursor-pointer"
            />
          </div>
          <div className="w-full h-full overflow-x-hidden overflow-y-auto">
            <iframe title={title} className="w-100 h-100" src={path} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewDocument;
