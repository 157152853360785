import React from "react";

// components
import { Table, Spin, TableProps, Switch, Popconfirm } from "antd";
import CreateTraining from "../misc/CreateTraining";
import Button from "../../../component/ui/form/Button";

// constants and icon
import { FaRegBuilding, FaEye, FaEdit, FaTrash } from "react-icons/fa";

// state
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  delete_training,
  get_trainings,
} from "../../../services/apis/training";
import { ITraining } from "../../../store/types/training";
import EditTraining from "../misc/EditTraining";
import { useNavigate } from "react-router-dom";

const Training = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const trainings = useAppSelector(({ training }) => training.trainings);
  const loading = useAppSelector(({ training }) => training.loading);

  const [show, showSet] = React.useState<boolean>(false);
  const [edit, editSet] = React.useState<{ show: boolean; id: string }>({
    show: false,
    id: "",
  });

  const onLoad = React.useCallback(() => {
    get_trainings(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: TableProps<ITraining>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (e) => <p className="">{e}</p>,
    },
    {
      title: "Amount",
      key: "amount",
      align: "center",
      dataIndex: "amount",
      render: (e) => <p className="">{e}</p>,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      align: "center",
      key: "address",
      render: (e) => <p>{e}</p>,
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      key: "date",
      render: (e) => <p>{e}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (e) => (
        <Switch checked={e === "1" ? true : false} onClick={() => null} />
      ),
    },
    {
      title: "Action",
      key: "id",
      align: "center",
      dataIndex: "id",
      render: (id) => (
        <div className="w-full flex justify-between items-center">
          <FaEye
            size={17}
            className="cursor-pointer"
            onClick={() => navigate(`/training/${id}`)}
          />

          <FaEdit
            size={17}
            className="cursor-pointer"
            color="blue"
            onClick={() => editSet({ show: true, id: id })}
          />

          <Popconfirm
            title="Are you sure you want to delete!"
            className="cursor-pointer"
            onConfirm={() => delete_training(dispatch, id)}
          >
            <FaTrash size={17} color="red" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Training</h2>
        <div className="w-[50%] flex items-center justify-end ">
          <Button
            title="Create"
            children={
              <>
                {loading && <Spin className="mr-1" />}
                <FaRegBuilding className="mr-2" />
              </>
            }
            onClick={() => showSet(true)}
            classname="mx-4 bg-[#67C748] w-[30%] text-white"
          />
        </div>
      </div>

      <div className="flex flex-1 flex-col mt-[2px]">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={trainings}
          loading={loading}
          className="mb-2"
          pagination={false}
        />
      </div>

      <CreateTraining show={show} onHandler={() => showSet(false)} />
      {edit.id && (
        <EditTraining
          show={edit.show}
          onHandler={() => {
            editSet({ show: false, id: "" });
          }}
          id={edit.id}
        />
      )}
    </div>
  );
};

export default Training;
