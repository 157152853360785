import React from "react";

// components
import { Modal, Table, TableProps } from "antd";

// router
import { useParams } from "react-router-dom";

// types and state
import { getCompanyDoc } from "../services/apis/company";
import { useAppDispatch, useAppSelector } from "../store";
import { IDocument } from "../store/types/company";

// constant and icons
import { FaEye } from "react-icons/fa";
import { real_date } from "../services/convert/date";

// const userData = store.get("zero-user")

const DocumentList = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(({ company }) => company.loading);
  const documents = useAppSelector(({ company }) => company.companyDoc);

  const [view, viewSet] = React.useState<{ show: boolean; path: string }>({
    show: false,
    path: "",
  });

  const onLoad = React.useCallback(() => {
    if (id) getCompanyDoc(id, dispatch);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: TableProps<IDocument>["columns"] = [
    {
      title: "Doc Name",
      dataIndex: "name",
      align: "center",
      key: "name",
      render: (e) => <span>{e}</span>,
    },
    {
      title: "Issue Date",
      dataIndex: "issuing_date",
      align: "center",
      key: "issuing_date",
      render: (e) => <span>{e ? real_date(e) : ""}</span>,
    },
    {
      title: "Expire",
      dataIndex: "expiring_date",
      align: "center",
      key: "expiring_date",
      render: (e) => <span>{e ? real_date(e) : ""}</span>,
    },
    {
      title: "Action",
      dataIndex: "path",
      align: "center",
      key: "path",
      render: (e) => (
        <div className="w-full flex items-center justify-center">
          <FaEye
            size={19}
            onClick={() => viewSet({ show: true, path: e })}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full">
      <Table
        columns={columns}
        rowKey="id"
        dataSource={documents}
        loading={loading}
        // className="mb-2"
        pagination={false}
      />

      {view.path && (
        <Modal
          title="View File"
          onCancel={() => viewSet({ show: false, path: "" })}
          open={view.show}
          footer={false}
          width="90%"
          bodyStyle={{ height: "90vh" }}
          centered={true}
        >
          <iframe title="ppt" className="w-100 h-100" src={view.path} />
        </Modal>
      )}
    </div>
  );
};

export default DocumentList;
