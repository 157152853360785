import React from "react";

// components
import ReactQuill from "react-quill";

// styles and version
import "react-quill/dist/quill.snow.css";

type IRichText = {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
};

const RichText: React.FC<IRichText> = ({ value, placeholder, onChange }) => {
  return (
    <div id="editor" className="w-full h-[250px] my-2 overflow-hidden">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        className="h-[200px]"
        placeholder={placeholder}
      />
    </div>
  );
};

export default RichText;
