/* eslint-disable no-unsafe-optional-chaining */
import axiosInstance from "../../utility/axios";

// state
import {
  setTotal,
  setUser,
  setUserDocFile,
  setUserFileLog,
  setUsers,
  setLoading,
  setMonthlyTotal,
  setProfiles,
} from "../../store/slices/user";
import { setUserLoading, disableUserLoading } from "../../store/slices/user";
import { openNotification } from "../../modules";
import { isAxiosError } from "axios";
import { AppDispatch } from "../../store/store";

export const getUsers = async (dispatch: AppDispatch, page: number = 1) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-users?page=${page}`);
    if (response.data) {
      dispatch(setUsers(response.data));
    }
  } catch (err: any) {
    const { data } = err?.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(setLoading());
};

export const getUserDetails = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/user-details/${id}`);
    if (response.data) {
      dispatch(setUser(response.data));
    }
  } catch (err: any) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(setLoading());
};

export const getUserFileLogs = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/user-file-logs/${id}`);
    if (response.data) {
      dispatch(setUserFileLog(response.data));
    }
  } catch (err: any) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(setLoading());
};

export const getUserDocFile = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/user-doc-shares/${id}`);
    if (response.data) {
      dispatch(setUserDocFile(response.data));
    }
  } catch (err: any) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(setLoading());
};

export const CreateUsers = async (data: any, dispatch: AppDispatch) => {
  dispatch(setUserLoading());
  try {
    const response = await axiosInstance.post("/create-user", data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err: any) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableUserLoading());
};

type IAddUser = (data: any, dispatch: AppDispatch) => Promise<void>;
export const AddUsers2Company: IAddUser = async (data, dispatch) => {
  dispatch(setUserLoading());
  try {
    const response = await axiosInstance.post("/add-to-company", data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err: any) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableUserLoading());
};

type ISetAdmin = (
  data: { email: string },
  dispatch: AppDispatch
) => Promise<void>;
export const setAdminCompany: ISetAdmin = async (data, dispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/make-user-admin", data);

    const { message } = response.data;
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err: any) {
    if (isAxiosError(err)) {
      // const { data } = err.response;
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data?.message,
      });
    }
  }
  dispatch(setLoading());
};

export const get_user_email = async (email: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-user-by-email/${email}`);

    const { data } = response.data;
    dispatch(setUser(data));
  } catch (err: any) {
    if (isAxiosError(err)) console.log(err);
  }
  dispatch(setLoading());
};

export const get_total_user = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/v1/get-total-user");

    const { data } = response.data;
    dispatch(setTotal(data));
  } catch (err: any) {
    if (isAxiosError(err)) console.log(err);
  }
  dispatch(setLoading());
};

export const monthly_total = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/v1/get-user-total-month");

    const { data } = response.data;
    dispatch(setMonthlyTotal(data));
  } catch (err: any) {
    if (isAxiosError(err)) console.log(err);
  }
};

// ==================================================================================================

export const get_profiles = async (
  dispatch: AppDispatch,
  query: string = ""
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/v1/company-employees${query}`);

    const { data } = response.data;
    dispatch(setProfiles(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      const { data, message } = err?.response?.data;
      console.log(data, message);
      // const { data } = err.response;
      // openNotification({
      //   type: "error",
      //   title: "Incorrect Credentials",
      //   description: data.message,
      // });
    }
  }
  dispatch(setLoading());
};
