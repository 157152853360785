import React from "react";

// components
import CreateCompany from "../misc/CreateCompany";
// import { Spin, Table, TableProps, Switch, Popconfirm } from "antd";
import Button from "../../../component/ui/form/Button";
// import { Collapse } from 'antd';

// constants and icon
import { FaRegBuilding, FaStar } from "react-icons/fa";
import { GoEye, GoTrashcan } from "react-icons/go";
import { VscFolderActive } from "react-icons/vsc";

// state
import { useNavigate } from "react-router-dom";
import {
  company_status,
  get_companies,
  premium_status,
} from "../../../services/apis/company";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ICompany } from "../../../store/types/company";
import Paginate from "../../../component/ui/paginate";
import { ColumnDef } from "@tanstack/react-table";
import DataTable from "../../../component/element/table";

const Company: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const companies = useAppSelector(({ company }) => company.companies);
  const last_page = useAppSelector(({ company }) => company.last_page);
  const current_page = useAppSelector(({ company }) => company.current_page);
  // const loading = useAppSelector(({ company }) => company.loading);

  const [create, createSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    get_companies(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: ColumnDef<ICompany>[] = [
    { accessorKey: "company_name", header: "Name" },
    { accessorKey: "company_email", header: "Email" },
    { accessorKey: "rc_number", header: "RC Number" },
    {
      accessorKey: "status",
      header: "Active",
      cell: ({ getValue, row }) => {
        const status = getValue() as string;
        const data = row.original;
        return (
          <VscFolderActive
            color={status === "0" ? "" : "green"}
            size={25}
            onClick={() => company_status(data.id, dispatch)}
            className="cursor-pointer"
          />
        );
      },
    },
    {
      accessorKey: "premium_status",
      header: () => <span className="w-full flex justify-center">Premium</span>,
      cell: ({ getValue, row }) => {
        const status = getValue() as string;
        const data = row.original;
        return (
          <div className="w-full flex justify-center">
            <FaStar
              color={status === "0" ? "" : "#FFD700"}
              size={25}
              onClick={() => premium_status(data.id, dispatch)}
              className="cursor-pointer"
            />
          </div>
        );
      },
    },
    {
      accessorKey: "id",
      header: () => <span className="w-full flex justify-center">Action</span>,
      cell: ({ getValue }) => {
        const id = getValue() as string;
        return (
          <div className="flex w-full justify-between ">
            <GoEye
              className="cursor-pointer"
              color="blue"
              size={25}
              onClick={() => navigate(`/company/details/${id}`)}
            />

            <GoTrashcan size={25} color="red" className="cursor-pointer" />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Company</h2>
        <div className="w-[50%] flex items-center justify-end ">
          <Button
            title="Create"
            children={<FaRegBuilding className="mr-2" />}
            onClick={() => createSet(true)}
            classname=" bg-[#67C748] w-[30%] text-white"
          />
        </div>
      </div>

      <div className="flex flex-1 flex-col mt-[10px]">
        <DataTable columns={columns} data={companies} />

        <Paginate
          current_page={current_page}
          last_page={last_page}
          onNext={() => null}
          onPrev={() => null}
        />
      </div>

      <CreateCompany visible={create} onHandler={() => createSet(false)} />
    </div>
  );
};

export default Company;
