import React from "react";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
// import { Modal } from "antd";
import Upload from "../../../component/modals/upload";
import Tab from "../../../component/ui/tabs/Tabs";
import Button from "../../../component/ui/form/Button";
import TextInput from "../../../component/element/forms/TextInput";
import TextMultipleInput from "../../../component/element/forms/TextMultipleInput";

// types and state
import {
  ICreateService as Create,
  ICreateServiceError,
} from "../../../store/types/service";
import { create_service } from "../../../services/apis/services";
import { useAppDispatch } from "../../../store";
import Modal from "../../../component/ui/modal";
import RichText from "../../../component/element/forms/RichText";

type ICreateService = {
  show: boolean;
  onCancel: () => void;
};

const CreateService: React.FC<ICreateService> = ({ show, onCancel }) => {
  const dispatch = useAppDispatch();
  const [data, dataSet] = React.useState<Create>({
    name: "",
    description: "",
    image: "",
    packages: [],
  });
  const [err, errSet] = React.useState<ICreateServiceError>({
    name: "",
    description: "",
    image: "",
    packages: "",
  });

  const { img_loader, uploadAvatar } = useUploadFiles();

  const onsubmit = async () => {
    let err;
    if (!data.packages.length) {
      err = true;
      errSet((prev) => ({ ...prev, packages: "warning" }));
    }
    if (!data.name) {
      err = true;
      errSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.description) {
      err = true;
      errSet((prev) => ({ ...prev, description: "warning" }));
    }
    if (err) return;
    const response = await create_service(data, dispatch);
    if (response) onCancel();
  };

  return (
    <Modal open={show}>
      <div className="row">
        <div className="my-2">
          <Upload
            title="Upload Image"
            name="image"
            status={err.image}
            loading={img_loader}
            completed={data.image ? true : false}
            onChange={async (res) => {
              const avatar = await uploadAvatar(res);
              if (avatar) {
                dataSet((prev) => ({ ...prev, image: avatar }));
                errSet((prev) => ({ ...prev, image: "" }));
              }
            }}
          />
        </div>

        <div className="my-2">
          <TextInput
            value={data.name}
            label="Name"
            name="name"
            placeholder="Service Name"
            type="text"
            status={err.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errSet((prev) => ({ ...prev, [name]: "" }));
            }}
          />
        </div>

        <div className="my-1">
          <RichText
            value={data.description}
            placeholder="Description"
            onChange={(val) => {
              dataSet((prev) => ({ ...prev, description: val }));
            }}
          />
        </div>

        <div className="my-1">
          {data.packages.length > 0 && (
            <div className="border py-2 px-3 my-3 rounded-lg flex">
              {data.packages.map((item, index) => (
                <Tab key={index} value={item} />
              ))}
            </div>
          )}
        </div>

        <div className="my-1">
          <TextMultipleInput
            container="my-2"
            onChange={(res) =>
              dataSet((prev) => ({
                ...prev,
                packages: [...data.packages, res],
              }))
            }
          />
        </div>

        <div className="flex w-full justify-center items-center">
          <div className="w-[30%]">
            <Button
              type="button"
              title="Submit"
              classname="text-white bg-primary w-[17%] "
              onClick={onsubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateService;
