import React from "react";

type IDataList = {
  title: string;
  name: string;
  icon?: React.ReactNode;
};

const DataList: React.FC<IDataList> = ({ title, name, icon }) => {
  return (
    <div className="flex justify-between items-center px-3 border-b py-2">
      <div className="font-bold">{title}</div>
      <div className="font-bold flex items-center">
        {name} {icon}
      </div>
    </div>
  );
};

export default DataList;
