// import { SmileOutlined, WarningOutlined } from "@ant-design/icons";
import { notification } from "antd";
// import React from "react";

type INotification = {
  type: "success" | "error";
  description?: string;
  title: string;
};

type IOpenNotification = (data: INotification) => void;
const openNotification: IOpenNotification = ({ title, description, type }) => {
  // const icons = (type === 'success' ?
  // <SmileOutlined color="#108ee9"/> : <WarningOutlined color="red />)
  notification.open({
    message: title.toUpperCase(),
    description: description,
    // icon: icons
  });
};

export default openNotification;
