import React from "react";

// state
import {
  ICreateCompany,
  ICreateCompanyError,
} from "../../../store/types/company";

const useCreateCompany = () => {
  const [data, setData] = React.useState<ICreateCompany>({
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_state: "",
    rc_number: "",
    company_country: "",
  });

  const [error, setError] = React.useState<ICreateCompanyError>({
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_state: "",
    company_country: "",
    rc_number: "",
  });

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  const onSelect = (name: string, value: string) => {
    setData((prev) => ({ ...prev, [name]: value }));
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  const onResetData = () => {
    setData({
      company_name: "",
      company_phone: "",
      company_email: "",
      company_website: "",
      company_address: "",
      company_state: "",
      rc_number: "",
      company_country: "",
    });
  };

  const onValidate = () => {
    let err;
    if (!data.company_name) {
      err = true;
      setError((prev) => ({ ...prev, company_name: "warning" }));
    }
    if (!data.company_address) {
      err = true;
      setError((prev) => ({ ...prev, company_address: "warning" }));
    }
    if (!data.company_country) {
      err = true;
      setError((prev) => ({ ...prev, company_country: "warning" }));
    }
    if (!data.company_email) {
      err = true;
      setError((prev) => ({ ...prev, company_email: "warning" }));
    }
    if (!data.company_phone) {
      err = true;
      setError((prev) => ({ ...prev, company_phone: "warning" }));
    }
    if (!data.company_state) {
      err = true;
      setError((prev) => ({ ...prev, company_state: "warning" }));
    }
    if (err) {
      return false;
    }
    return true;
  };

  return {
    data,
    error,
    onSelect,
    onValidate,
    onResetData,
    onChangeText,
  };
};

export default useCreateCompany;
