import React from "react";

// constants
import { BsPlus } from "react-icons/bs";

type ITextMultipleInput = {
  container?: string;
  onChange: (data: string) => void;
};

const TextMultipleInput: React.FC<ITextMultipleInput> = ({
  container,
  onChange,
}) => {
  const [data, dataSet] = React.useState<string>("");

  return (
    <div
      className={`w-full flex border rounded-lg overflow-hidden px-2 ${container}`}
    >
      <input
        type="text"
        // name="multi_text"
        value={data}
        className="outline-none flex-1"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          dataSet(e.target.value)
        }
      />
      <BsPlus
        size={30}
        className="cursor-pointer"
        onClick={() => {
          if (data) {
            dataSet("");
            onChange(data);
          }
        }}
      />
    </div>
  );
};

export default TextMultipleInput;
