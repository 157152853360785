import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyUser, IUserState } from "../types/user";
import { IProfileResponse } from "../types/employee";

const initialState: IUserState = {
  users: [],
  profiles: [],
  docFile: [],
  fileLogs: [],
  user: null,
  monthly_total: 0,
  total: 0,
  current_page: 1,
  last_page: 1,
  loading: false,
};

export const opportunity = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
    },
    setUser: (state, action: PayloadAction<ICompanyUser>) => {
      state.user = action.payload;
    },
    setUserFileLog: (state, action) => {
      state.fileLogs = action.payload;
    },
    setUserDocFile: (state, action) => {
      state.docFile = action.payload;
    },
    setSearch: (state, action) => {
      state.users = action.payload.data;
    },
    setUserLoading: (state) => {
      state.loading = true;
    },
    disableUserLoading: (state) => {
      state.loading = false;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setMonthlyTotal: (state, action: PayloadAction<number>) => {
      state.monthly_total = action.payload;
    },
    setProfiles: (state, action: PayloadAction<IProfileResponse>) => {
      state.profiles = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setUser,
  setUserLoading,
  setUserDocFile,
  setUserFileLog,
  disableUserLoading,
  setUsers,
  setTotal,
  setMonthlyTotal,
  setProfiles,
  setLoading,
} = opportunity.actions;

export default opportunity.reducer;
