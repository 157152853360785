export const real_date = (date: Date | string) => {
  const date_format = new Date(date)?.toString();
  return date_format?.slice(4, 16);
};

export const real_date_time = (date: Date | string) => {
  const date_format = new Date(date)?.toString();
  return date_format?.slice(4, 25);
};

export const date_time = (date: Date | string) => {
  return new Date(date).toISOString().slice(0, 19).replace("T", " ");
};

export const format_date_time = (timestamp: Date) => {
  return new Date(timestamp).toString().substring(4, 21);
};
