import React from "react";

type IMemberTag = {
  value: string;
  className?: string;
};

const MemberTag: React.FC<IMemberTag> = ({ value, className }) => {
  return (
    <span className={`px-[14px] py-[4px] rounded-xl ${className}`}>
      {value}
    </span>
  );
};

export default MemberTag;
