import React from "react";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import Upload from "../../../component/modals/upload";
import TextBox from "../../../component/element/forms/TextBox";
import Button from "../../../component/element/forms/Button";
import TextInput from "../../../component/element/forms/TextInput";
import SelectDate from "../../../component/element/forms/SelectDate";
import SelectOption from "../../../component/element/forms/SelectOption";

// state
import {
  create_opportunity,
  get_opportunities_type,
} from "../../../services/apis/opportunity";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  ICreateOpportunity,
  ICreateOpportunityError,
} from "../../../store/types/opportunity";
import Modal from "../../../component/ui/modal";
import { FaTimes } from "react-icons/fa";

type ICreateOpportunityType = {
  visible: boolean;
  onHandler: () => void;
};

const CreateOpportunity: React.FC<ICreateOpportunityType> = ({
  visible,
  onHandler,
}) => {
  const dispatch = useAppDispatch();
  const types = useAppSelector(
    ({ opportunity }) => opportunity.opportunity_types
  );

  const { uploadAvatar, uploadFile, img_loader, file_loader } =
    useUploadFiles();
  // const [loading] = useState(false);

  const [data, dataSet] = React.useState<ICreateOpportunity>({
    opportunity_name: "",
    opportunity_file: "",
    opportunity_description: "",
    expire_date: "",
    opportunity_image: "",
    opportunity_type: "",
  });
  const [error, errorSet] = React.useState<ICreateOpportunityError>({
    opportunity_name: "",
    opportunity_file: "",
    opportunity_description: "",
    expire_date: "",
    opportunity_image: "",
  });

  const clear = () => {
    dataSet({
      opportunity_name: "",
      opportunity_file: "",
      opportunity_description: "",
      expire_date: "",
      opportunity_image: "",
      opportunity_type: "",
    });
  };

  const handleOk = async () => {
    let err;
    if (!data.opportunity_name) {
      err = true;
    }
    if (!data.opportunity_description) {
      err = true;
    }
    if (!data.opportunity_image) {
      err = true;
    }
    if (!data.opportunity_file) {
      err = true;
    }
    if (err) return;
    const response = await create_opportunity(data, dispatch);
    if (response) {
      clear();
      onHandler();
    }
  };

  const onLoad = React.useCallback(() => {
    get_opportunities_type(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal open={visible}>
      <div className="flex w-full items-center justify-end">
        <div className="">
          <FaTimes
            onClick={() => {
              clear();
              onHandler();
            }}
            size={24}
            color="#9CA3AF"
            className="cursor-pointer"
          />
        </div>
      </div>
      <div className="row">
        <div className="my-[5px]">
          <TextInput
            label="Opportunity Name"
            name="opportunity_name"
            placeholder="Opportunity Name"
            type="text"
            value={data.opportunity_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
            }}
          />
        </div>

        <div className="w-[50%] my-[5px]">
          <SelectOption
            items={types}
            label="Opportunity Type"
            placeholder="Select Type"
            value={data.opportunity_type}
            onChange={(selected) => {
              dataSet((prev) => ({
                ...prev,
                opportunity_type: selected?.toString(),
              }));
              // setError(prev =>({...prev, opportun}))
            }}
          />
        </div>
        <div className="w-[50%] my-[5px]">
          <SelectDate
            label="Select Expire Date"
            placeholder="Select Date"
            onChange={(date) => {
              dataSet((prev) => ({ ...prev, expire_date: date }));
              // setData((prev) => ({ ...prev, expire_date: date }));
            }}
          />
        </div>

        <div className="w-full my-[5px]">
          <TextBox
            label="Opportunity Description"
            name="opportunity_description"
            value={data.opportunity_description}
            placeholder="Opportunity Description"
            onChange={(name, value) =>
              dataSet((prev) => ({ ...prev, [name]: value }))
            }
          />
        </div>

        <div className="flex w-full">
          <div className="my-2 w-[50%] mr-[2px]">
            <Upload
              title="Upload Image"
              name="opportunity_image"
              loading={img_loader}
              status={error.opportunity_image}
              completed={data.opportunity_image ? true : false}
              onChange={async (res) => {
                const avatar = await uploadAvatar(res);
                if (typeof avatar === "string") {
                  dataSet((prev) => ({ ...prev, opportunity_image: avatar }));
                  errorSet((prev) => ({ ...prev, opportunity_image: "" }));
                }
              }}
            />
          </div>

          <div className="my-2 w-[50%] ml-[2px]">
            <Upload
              title="Upload opportunity File"
              name="opportunity_file"
              loading={file_loader}
              status={error.opportunity_file}
              completed={data.opportunity_file ? true : false}
              onChange={async (res) => {
                const avatar = await uploadFile(res);
                if (typeof avatar === "string") {
                  dataSet((prev) => ({ ...prev, opportunity_file: avatar }));
                  errorSet((prev) => ({ ...prev, opportunity_file: "" }));
                }
              }}
            />
          </div>
        </div>

        <div>
          <Button title="Submit" type="button" onClick={handleOk} />
        </div>
      </div>
    </Modal>
  );
};

export default CreateOpportunity;
