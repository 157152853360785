import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IBlogState,
  ICategories,
  IComment,
  IGetBlog,
  IGetBlogs,
  IResponseBlogs,
} from "../types/blog";
import { ISimpleData } from "../types/misc";

const initialState: IBlogState = {
  blogs: [],
  blog: null,
  blogTags: [],
  comments: [],
  categories: [],
  loading: false,
  last_page: 1,
  current_page: 1,
};

export const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogs: (state, action: PayloadAction<IResponseBlogs>) => {
      state.blogs = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
    },
    setBlog: (state, action: PayloadAction<IGetBlog>) => {
      state.blog = action.payload;
    },
    createBlog: (state, action: PayloadAction<IGetBlog>) => {
      state.blogs = [action.payload, ...state.blogs];
    },
    editBlog: (state, action: PayloadAction<IGetBlogs>) => {
      state.blogs = state.blogs.map((item) => {
        if (action.payload.id?.toString() === item.id?.toString())
          return action.payload;
        return item;
      });
    },
    updateBlog: (state, action: PayloadAction<IGetBlog>) => {
      state.blog = action.payload;
    },
    deleteBlog: (state, action: PayloadAction<string>) => {
      state.blogs = state.blogs.filter(
        (blog) => blog.id?.toString() !== action.payload
      );
    },
    setComments: (state, action: PayloadAction<IComment[]>) => {
      state.comments = action.payload;
    },
    setCategories: (state, action: PayloadAction<ICategories>) => {
      state.categories = action.payload;
    },
    createCategories: (state, action: PayloadAction<ISimpleData>) => {
      state.categories = [action.payload, ...state.categories];
    },
    editCategory: (state, action: PayloadAction<ISimpleData>) => {
      state.categories = state.categories.map((item) => {
        if (item.id?.toString() === action.payload.id?.toString())
          return action.payload;
        return item;
      });
    },
    deleteCategory: (state, action: PayloadAction<string>) => {
      state.categories = state.categories.filter(
        (item) => item.id?.toString() !== action.payload?.toString()
      );
    },

    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCategories,
  createCategories,
  editCategory,
  deleteCategory,
  setBlogs,
  setBlog,
  createBlog,
  editBlog,
  updateBlog,
  deleteBlog,
  setComments,
  setLoading,
} = blog.actions;

export default blog.reducer;
