/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from "../../utility/axios";

// state
import { openNotification } from "../../modules";
import {
  disableTrainingLoading,
  setTrainingLoading,
  setTrainings,
  setTotal,
  setLoading,
  setMonthTotal,
  setTraining,
  createTraining,
  editTraining,
} from "../../store/slices/training";
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";
import { ITrainingCreate } from "../../store/types/training";

export const get_trainings = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-trainings");

    dispatch(setTrainings(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err);
  }
  dispatch(setLoading());
};

export const get_training = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/v1/get-training/${id}`);

    const { data } = response.data;
    dispatch(setTraining(data));
  } catch (error: any) {
    if (isAxiosError(error)) console.log(error?.response?.data);
  }
  dispatch(setLoading());
};

export const create = async (
  formData: ITrainingCreate,
  dispatch: AppDispatch
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/v1/create-training", formData);

    const { message, data } = response.data;
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
    dispatch(createTraining(data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: err?.response?.data.message,
      });
    }
  }
  dispatch(setLoading());
};

export const edit = async (form_data: any, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch(`/v1/edit-training`, form_data);

    const { message, data } = response.data;
    dispatch(editTraining(data));
    openNotification({
      type: "success",
      title: "Successful!",
      description: message,
    });
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({
        type: "error",
        title: "Incorrect Credentials",
        description: message,
      });
    }
  }
  dispatch(setLoading());
};

export const delete_training = async (dispatch: AppDispatch, id: string) => {
  dispatch(setTrainingLoading());
  try {
    await axiosInstance.get(`/delete-training/${id}`);
    // console.log(response.data);
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(disableTrainingLoading());
};

export const get_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("v1/get-total-training");
    // console.log(response.data);

    const { data } = response.data;
    dispatch(setTotal(data));
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(setLoading());
};

export const monthly_total = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("v1/get-total-month");
    // console.log(response.data);

    const { data } = response.data;
    dispatch(setMonthTotal(data));
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(setLoading());
};
