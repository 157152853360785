import React from "react";

// components
import { useAppDispatch, useAppSelector } from "../../../store";
import Button from "../../../component/element/forms/Button";

// icons and constants
import { FaUnlockAlt, FaLock } from "react-icons/fa";

// state and dispatch
import { IUpdatePassword } from "../../../store/types/setting";
import { update_password } from "../../../services/apis/setting";
import TextInput from "../../../component/element/forms/TextInput";

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ setting }) => setting.loading);
  const [data, dataSet] = React.useState<IUpdatePassword>({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="w-full h-full  py-[40px] px-[10px]">
      <div className="my-2">
        <TextInput
          value={data.old_password}
          label="Old Password"
          name="old_password"
          onChange={onChange}
          icon={<FaUnlockAlt />}
          placeholder="Old Password"
          type="password"
        />
      </div>

      <div className="my-2">
        <TextInput
          value={data.password}
          label="Password"
          name="password"
          onChange={onChange}
          icon={<FaLock />}
          placeholder="New Password"
          type="password"
        />
      </div>

      <div className="my-2">
        <TextInput
          value={data.password_confirmation}
          label="Confirm New Password"
          icon={<FaLock />}
          name="password_confirmation"
          onChange={onChange}
          placeholder="Confirm New Password"
          type="password"
        />
      </div>

      <div className="my-4">
        <Button
          onClick={() => {
            if (
              data.old_password &&
              data.password &&
              data.password === data.password_confirmation
            ) {
              update_password(dispatch, data);
            }
          }}
          title="Update Password"
          type="button"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
