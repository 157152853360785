import React from "react";

// components
import BlogCard from "../misc/BlogCard";
import Button from "../../../component/ui/form/Button";

// constants and icon
import { IoDuplicateOutline } from "react-icons/io5";
import { FaRegBuilding } from "react-icons/fa";

// router
import { useNavigate } from "react-router-dom";

// state and service
import { get_blogs } from "../../../services/apis/blog";
import { useAppDispatch, useAppSelector } from "../../../store";

const Blog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const blogs = useAppSelector(({ blog }) => blog?.blogs);
  // const loading = useAppSelector(({ blog }) => blog?.loading);

  const onLoad = React.useCallback(() => {
    get_blogs(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Blogs</h2>

        <div className="w-[50%] flex items-center justify-end ">
          <Button
            title="Create"
            children={<FaRegBuilding className="mr-2" />}
            onClick={() => navigate("/create-blog")}
            classname="mx-4 bg-[#67C748] w-[30%] text-white"
          />

          <Button
            title="Categories"
            children={<IoDuplicateOutline className="mr-2" />}
            onClick={() => navigate("/blog-categries")}
            classname="mr-2 bg-[#ddd] w-[35%] text-white"
          />
        </div>
      </div>

      <div className="w-full flex flex-1 flex-wrap justify-evenly mt-3">
        {blogs.map((item) => (
          <BlogCard data={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default Blog;
