import React from "react";

// import { real_date } from "../../../services/convert/date";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_trainings } from "../../../services/apis/training";

const Training = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ request }) => request.loading);
  const trainings = useAppSelector(({ training }) => training.trainings);

  const onLoad = React.useCallback(() => {
    get_trainings(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  return (
    <div className="w-[35%] border px-2 py-3">
      {!loading && (
        <>
          {" "}
          <h4 className="text-left text-[17px] font-bold max-h-[h-30vh]">
            Training
          </h4>
          <hr />
          <div className="w-full">
            {trainings &&
              trainings.slice(0, 5).map((item) => (
                <div
                  key={item?.id}
                  className="w-full my-1 border-b flex justify-between items-center text-[16px]"
                >
                  {/* <div className="w-[50%] px-2">
                    {item.service?.name || item?.document?.name}
                  </div>
                  <div className="w-[30%]">{item.request_status?.name}</div>
                  <div className="w-[20%]">
                    {item.created_at ? real_date(item.created_at) : ""}
                  </div> */}
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Training;
