import axiosInstance from "../../utility/axios";

// state
import { setLoading, disableLoading } from "../../store/slices/loading";
// import { openNotification } from "../../modules";
import {
  setAllCountry,
  setCountry,
  setState,
} from "../../store/slices/country";
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";

export const getCountry = async (dispatch: AppDispatch, page: number = 1) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-all-countries?page=${page}`);
    if (response.data) {
      dispatch(setCountry(response.data));
    }
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(disableLoading());
};

export const getState = async (id: string, dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-states/${id}`);

    const { data } = response.data;
    dispatch(setState(data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(disableLoading());
};

export const getAllCountry = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/all-country");

    dispatch(setAllCountry(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
  }
  dispatch(disableLoading());
};
