import React from "react";

// components
import { Modal } from "antd";
import Button from "../../../component/element/forms/Button";
import TextInput from "../../../component/element/forms/TextInput";

// constants and icon
import { AiOutlineClose } from "react-icons/ai";
import { IPriceUpdate } from "../../../store/types/request";

type IEditPrice = {
  id: string;
  visible: boolean;
  onCloseView: (data: IPriceUpdate) => void;
};

export const EditPrice: React.FC<IEditPrice> = ({
  id,
  visible,
  onCloseView,
}) => {
  const [data, dataSet] = React.useState<IPriceUpdate>({
    id: "",
    price: "",
    payment_link: "",
    submitted_by: "admin",
  });

  const onLoad = React.useCallback(() => {
    if (id) dataSet((prev) => ({ ...prev, id }));
  }, [id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <Modal
        open={visible}
        closable={false}
        footer={false}
        onCancel={() => null}
      >
        <div className="w-full">
          <div className="w-full flex justify-end items-center mb-3">
            <AiOutlineClose
              size={25}
              color="red"
              onClick={() => {
                const newData = {
                  id: "",
                  price: "",
                  payment_link: "",
                  submitted_by: "",
                };
                onCloseView(newData);
                dataSet(newData);
              }}
            />
          </div>

          <div className="my-2">
            <TextInput
              type="text"
              label="Price"
              placeholder="Enter Price"
              name="price"
              value={data.price}
              onChange={onChange}
            />
          </div>

          <div className="my-2">
            <TextInput
              type="text"
              label="Payment Link"
              placeholder="Enter Payment Link"
              name="payment_link"
              value={data.payment_link}
              onChange={onChange}
            />
          </div>

          <div className="my-4">
            <Button
              title="Submit"
              type="button"
              onClick={() => onCloseView(data)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
