import React from "react";

// component
import { Skeleton } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_total } from "../../../services/apis/services";
import { get_total as get_request_total } from "../../../services/apis/requests";

const TotalServive = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ service }) => service.loading);
  const service = useAppSelector(({ service }) => service.total);
  const loading_u = useAppSelector(({ request }) => request.loading);
  const request = useAppSelector(({ request }) => request.total);

  const onLoad = React.useCallback(() => {
    get_total(dispatch);
    get_request_total(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full h-[120px] shadow-sm rounded-2xl p-4 flex justify-between bg-white">
      <h5 className="text-lg font-medium">Services</h5>

      <div>
        <div>
          {loading ? (
            <Skeleton.Button active={true} block />
          ) : (
            <h5 className="text-right text-4xl font-bold">{service}</h5>
          )}
        </div>
        <div className="flex items-center justify-center gap-2">
          <h5>Requests</h5>
          {loading_u ? (
            <Skeleton.Button
              active={true}
              // block
              // style={{ height: "20px", width: "10px" }}
            />
          ) : (
            <span className="!text-primary self-center">{request}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalServive;
