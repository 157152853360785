import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany, ICompanyStatus, IDocument } from "../types/company";

const initialState: ICompanyStatus = {
  total: 0,
  monthly_total: 0,
  companies: [],
  search: [],
  companyEmp: [],
  companyDoc: [],
  logs: [],
  loading: false,
  company: null,
  last_page: 1,
  current_page: 1,
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
    },
    createCompany: (state, action: PayloadAction<ICompany>) => {
      state.companies = [action.payload, ...state.companies];
    },
    setCompany: (state, action: PayloadAction<ICompany>) => {
      state.company = action.payload;
    },
    setCompanyDoc: (state, action) => {
      state.companyDoc = action.payload;
    },
    createDocument: (state, action: PayloadAction<IDocument>) => {
      state.companyDoc = [action.payload, ...state.companyDoc];
    },
    setCompanyEmp: (state, action) => {
      state.companyEmp = action.payload;
    },
    setSearch: (state, action) => {
      state.companies = action.payload.data;
    },
    setFileLogs: (state, action) => {
      state.logs = action.payload;
    },
    setCompanyLoading: (state) => {
      state.loading = true;
    },
    disableCompanyLoading: (state) => {
      state.loading = false;
    },

    // done
    setStatusUpdate: (state, action: PayloadAction<ICompany>) => {
      state.companies = state.companies.map((company) => {
        if (company.id === action.payload.id) return action.payload;
        return company;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setMonthTotal: (state, action: PayloadAction<number>) => {
      state.monthly_total = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setCompanyDoc,
  createDocument,
  setCompanyEmp,
  setCompany,
  setCompanyLoading,
  disableCompanyLoading,
  setCompanies,
  createCompany,
  setStatusUpdate,
  setFileLogs,
  setLoading,
  setTotal,
  setMonthTotal,
} = company.actions;

export default company.reducer;
