import React from "react";

// router and linker
import { Popconfirm, Switch } from "antd";

// state and sipatc
import { IGetBlogs } from "../../../store/types/blog";
import { destroy_blog, update_status } from "../../../services/apis/blog";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";

// constants
import { FaHeart, FaCommentAlt, FaTrash } from "react-icons/fa";

type IBlogCard = {
  data: IGetBlogs;
};

const BlogCard: React.FC<IBlogCard> = ({ data }) => {
  // destroy_blog
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ blog }) => blog.loading);
  return (
    <div className="w-[30%] my-4 mx-2">
      <div
        className="h-[27vh] flex relative justify-center items-center border-t border-r border-l border-b-0 border-[white] cursor-pointer"
        // onClick={() => navigate(`/blog/${data.id}`)}
      >
        <img
          src={data.image}
          alt={data.title}
          className="w-full h-[100%] object-fill"
        />
        <Popconfirm
          title="Are you sure you want to delete!"
          onCancel={() => null}
          onConfirm={() => destroy_blog(dispatch, data.id)}
          className="absolute top-2 right-2"
        >
          <FaTrash color="red" size={16} />
        </Popconfirm>
      </div>
      <div className="h-[20vh] flex flex-col justify-between px-2 py-1 overflow-hidden bg-[white]">
        <div>
          <h2
            className="font-bold cursor-pointer"
            onClick={() => navigate(`/blog/${data.id}`)}
          >
            {data.title}
          </h2>
        </div>
        <div>
          <div className="flex w-full justify-end items-center mb-1">
            <FaHeart color="red" className="mx-1" />
            <span> {data.likes_count}</span>
            <FaCommentAlt color="blue" className="mx-1" />
            <span>{data.comments_count}</span>
          </div>
          <div className="flex w-full justify-between">
            <span
              className="bg-[#67C748] px-2 py-1 text-[.79em] font-bold text-white rounded-bl-md rounded-tr-md cursor-pointer"
              onClick={() => navigate(`/blog/${data.id}`)}
            >
              {data?.category?.name}
            </span>
            <Switch
              loading={loading}
              checked={data.published?.toString() === "0" ? false : true}
              onChange={() => update_status({ id: data.id }, dispatch)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
