import React from "react";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import { Modal } from "antd";
import Upload from "../../../component/modals/upload";
import TextInput from "../../../component/element/forms/TextInput";
import SelectDate from "../../../component/element/forms/SelectDate";

// state
import { useAppDispatch } from "../../../store";
import {
  ICompanyDocument,
  ICompanyDocumentError,
} from "../../../store/types/company";
import { create_company_docs } from "../../../services/apis/company";
import { useParams } from "react-router-dom";

type IUploadDocument = {
  visible: boolean;
  onHandler: () => void;
};

const UploadDocument: React.FC<IUploadDocument> = ({ visible, onHandler }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { file_loader, uploadFile } = useUploadFiles();
  const [data, dataSet] = React.useState<ICompanyDocument>({
    name: "",
    company_id: "",
    issuing_body: "",
    expiring_date: "",
    issuing_date: "",
    path: "",
  });
  const [error, errorSet] = React.useState<ICompanyDocumentError>({
    company_id: "",
    expiring_date: "",
    issuing_body: "",
    issuing_date: "",
    name: "",
    path: "",
  });

  const handleOk = () => {
    let err;
    if (!data.company_id) {
      err = true;
      errorSet((prev) => ({ ...prev, company_id: "warning" }));
    }
    if (!data.name) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.expiring_date) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.issuing_body) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.issuing_date) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.path) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    console.log(error);
    if (err) return;
    create_company_docs(data, dispatch);
    onHandler?.();
  };

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onLoad = React.useCallback(() => {
    if (id) dataSet((prev) => ({ ...prev, company_id: id }));
  }, [id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      title="Upload Document"
      open={visible}
      onOk={handleOk}
      onCancel={onHandler}
      width="50%"
      centered={true}
    >
      <div className="w-full flex items-center justify-between mt-3">
        <div className="w-[49%]">
          <TextInput
            label="Document Name"
            name="name"
            onChange={onChangeText}
            placeholder="Document Name"
            type="text"
            value={data.name}
            status={error.name}
          />
        </div>

        <div className="w-[49%]">
          <TextInput
            label="Issuing Body"
            name="issuing_body"
            onChange={onChangeText}
            placeholder="Issuing Body"
            type="text"
            value={data.issuing_body}
            status={error.issuing_body}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-between mt-3">
        <div className="w-[49%]">
          <SelectDate
            label="Issuing Date"
            onChange={(res) =>
              dataSet((prev) => ({ ...prev, issuing_date: res }))
            }
            placeholder="Issuing Date"
          />
        </div>

        <div className="w-[49%]">
          <SelectDate
            label="Expiring Date"
            onChange={(res) =>
              dataSet((prev) => ({ ...prev, expiring_date: res }))
            }
            placeholder="Expriring Date"
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center mb-2 mt-4">
        <div className="w-[70%]">
          <Upload
            title="Upload opportunity File"
            name="opportunity_file"
            loading={file_loader}
            status={error.path}
            completed={data.path ? true : false}
            onChange={async (res) => {
              const avatar = await uploadFile(res);
              if (typeof avatar === "string") {
                dataSet((prev) => ({ ...prev, path: avatar }));
                errorSet((prev) => ({ ...prev, path: "" }));
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadDocument;
