import React from "react";
import useEditService from "../hooks/useEditService";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import Modal from "../../../component/ui/modal";
import Tab from "../../../component/ui/tabs/Tabs";
import Upload from "../../../component/modals/upload";
import Button from "../../../component/ui/form/Button";
import TextInput from "../../../component/element/forms/TextInput";
import TextMultipleInput from "../../../component/element/forms/TextMultipleInput";

// constants
import { FaTimes } from "react-icons/fa";

// types and state
import { edit_service, get_service } from "../../../services/apis/services";
import { useAppDispatch } from "../../../store";
import RichText from "../../../component/element/forms/RichText";

type IEditService = {
  id: string;
  show: boolean;
  onCancel: () => void;
};

const EditService: React.FC<IEditService> = ({ id, show, onCancel }) => {
  const dispatch = useAppDispatch();
  const { data, err, dataSet, errSet } = useEditService();
  const { img_loader, uploadAvatar } = useUploadFiles();

  const onLoad = React.useCallback(() => {
    if (id) get_service(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onsubmit = async () => {
    let err;
    if (!data.id) {
      err = true;
      errSet((prev) => ({ ...prev, id: "warning" }));
    }
    if (!data.name) {
      err = true;
      errSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.description) {
      err = true;
      errSet((prev) => ({ ...prev, description: "warning" }));
    }
    if (err) return;
    const response = await edit_service(data, dispatch);
    if (response) onCancel();
  };

  return (
    <Modal open={show}>
      <div className="flex w-full items-center justify-end">
        <div className="">
          <FaTimes
            onClick={onCancel}
            size={24}
            color="#9CA3AF"
            className="cursor-pointer"
          />
        </div>
      </div>

      <div className="my-2">
        <Upload
          title="Upload Image"
          name="description"
          status={err.image}
          loading={img_loader}
          completed={data.description ? true : false}
          onChange={async (res) => {
            const avatar = await uploadAvatar(res);
            if (avatar) {
              dataSet((prev) => ({ ...prev, image: avatar }));
              errSet((prev) => ({ ...prev, image: "" }));
            }
          }}
        />
      </div>

      <div className="row">
        <div className="my-2">
          <TextInput
            value={data.name}
            label="Name"
            name="name"
            placeholder="Service Name"
            type="text"
            status={err.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errSet((prev) => ({ ...prev, [name]: "" }));
            }}
          />
        </div>

        <div className="my-1">
          {data.packages.length > 0 && (
            <div className="border py-2 px-3 my-3 rounded-lg flex">
              {data.packages.map((item, index) => (
                <Tab key={index} value={item} />
              ))}
            </div>
          )}
        </div>

        <div className="my-1">
          <TextMultipleInput
            container="my-2"
            onChange={(res) => {
              dataSet((prev) => ({
                ...prev,
                packages: [...data.packages, res],
              }));
              errSet((prev) => ({ ...prev, packages: "" }));
            }}
          />
        </div>

        <RichText
          value={data.description}
          placeholder="Desccription"
          onChange={(value) => {
            dataSet((prev) => ({ ...prev, description: value }));
            errSet((prev) => ({ ...prev, description: "" }));
          }}
        />

        <div className="flex w-full justify-center items-center">
          <div className="w-[30%]">
            <Button
              type="button"
              title="Submit"
              classname="text-white bg-primary"
              onClick={onsubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditService;
