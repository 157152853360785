import React from "react";

// components
import SideNav from "./components/SideNav";

// new
import Navbar from "./components/Navbar";

type ITheme = {
  children: React.ReactNode;
};

const Theme: React.FC<ITheme> = ({ children }) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  return (
     <main className="w-screen h-screen grid grid-cols-[auto,1fr] xl:grid-cols-[auto,1fr] overflow-hidden ">
      <SideNav collapse={collapsed} />
      <main className="grid grid-rows-[64px,1fr]">
        <Navbar
          collapse={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
        />

        <main className="bg-orange-100 overflow-x-hidden overflow-y-auto p-9 bg-gray">
          {children}
        </main>
      </main>
    </main>
  );
};

export default Theme;
