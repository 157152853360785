import React from "react";

type IAuthScreen = {
  children: React.ReactNode;
};

const AuthScreen: React.FC<IAuthScreen> = ({ children }) => {
  return <div className="auth_page">{children}</div>;
};

export default AuthScreen;
