import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_blog, get_comments } from "../../../services/apis/blog";
import { useAppDispatch, useAppSelector } from "../../../store";
import Button from "../../../component/ui/form/Button";
import {
  FaLongArrowAltLeft,
  FaEdit,
  FaHeart,
  FaCommentAlt,
} from "react-icons/fa";
import { real_date_time } from "../../../services/convert/date";

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const blog = useAppSelector(({ blog }) => blog.blog);
  const comments = useAppSelector(({ blog }) => blog.comments);

  const onLoad = React.useCallback(() => {
    if (id) {
      get_blog(id, dispatch);
      get_comments(dispatch, id);
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  console.log(comments);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center px-2 py-1">
        <h2 className=" font-bold text-[1.2em] capitalize">Blog</h2>

        <div className="w-[50%] flex items-center justify-end ">
          {id && (
            <Button
              title="Edit"
              children={<FaEdit className="mr-2" />}
              onClick={() => navigate(`/edit-blog/${id}`)}
              classname="mx-4 bg-[#67C748] w-[30%] text-white"
            />
          )}

          <Button
            title="Back"
            children={<FaLongArrowAltLeft className="mr-2" />}
            onClick={() => navigate(-1)}
            classname="mr-2 bg-[#ddd] w-[35%] text-white"
          />
        </div>
      </div>

      <div className="px-2 my-2 mb-4 relative flex justify-between">
        <div className="w-[70%]">
          <div className="h-[50vh] w-full overflow-hidden">
            <img
              src={blog?.image}
              alt={blog?.title}
              className="w-full h-full object-none"
            />
          </div>

          <div className="flex w-full justify-between items-center mb-1 px-3">
            <div>
              <span className="bg-[#67C748] px-2 py-1 text-[1rem] font-bold text-white rounded-bl-md rounded-tr-md">
                {blog?.category?.name}
              </span>
            </div>
            <div className="w-[40%] flex justify-end items-center">
              <FaHeart size={25} color="red" className="mx-2" />
              <span className="text-[1.5rem] mr-2"> {blog?.likes_count}</span>
              <FaCommentAlt size={25} color="blue" className="mx-2" />
              <span className="text-[1.5rem] mr-2">{blog?.comments_count}</span>
            </div>
          </div>

          <h2 className="text-center text-[1.5rem] font-bold">{blog?.title}</h2>

          <div
            dangerouslySetInnerHTML={{ __html: blog?.article || "" }}
            className="py-4"
          />

          <h2 className="text-right font-bold">
            <span className=" bg-[#67C748] text-white rounded-bl-md rounded-tr-md px-2 py-1">
              Created At:
              {blog?.created_at ? real_date_time(blog.created_at) : ""}
            </span>
          </h2>
        </div>

        <div className="w-[29%] border max-h-[75vh] py-2 px-1 overflow-hidden">
          <h2 className="text-[1rem] font-bold text-center">Comments</h2>
          <div className="overflow-y-auto overflow-x-hidden h-[100%]">
            {comments.map((comment) => (
              <div
                key={comment?.id}
                className="bg-[white] my-2 px-1 py-2 rounded-md"
              >
                <div className="flex w-full items-center py-2">
                  <img
                    src={comment.user?.profile?.avatar}
                    alt={comment.user?.profile?.name}
                    className="w-[40px] h-[40px] border border-[#67C748] rounded-full mr-1"
                  />
                  <span className="text-[1rem]">
                    {comment.user?.profile?.name}
                  </span>
                </div>

                <p className="text-[.65rem] font-700">{comment?.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
