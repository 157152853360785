import React from "react";

// components
import { Modal } from "antd";
import Button from "../../../component/element/forms/Button";
import TextInput from "../../../component/element/forms/TextInput";

// types
import { IAdminChangePassword, IAdminUser } from "../../../store/types/setting";

// constants and icons
import user_img from "../../../assets/images/user/user.jpg";
import { format_date_time } from "../../../services/convert/date";
import { update_admin_password } from "../../../services/apis/setting";
import { useAppDispatch } from "../../../store";

type IAdminUserType = {
  show: boolean;
  data: IAdminUser;
  onCancel: () => void;
};

const AdminUser: React.FC<IAdminUserType> = ({ data, show, onCancel }) => {
  const dispatch = useAppDispatch();
  const [form, formData] = React.useState<IAdminChangePassword>({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formData((prev) => ({ ...prev, [name]: value }));
  };

  const onLoad = React.useCallback(() => {
    if (data.email) formData((prev) => ({ ...prev, email: data.email }));
  }, [data.email]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      open={show}
      onCancel={onCancel}
      closable={false}
      footer={[]}
      centered={true}
      width="70%"
    >
      <div className="w-full flex justify-center items-center">
        <div className="border w-[58%] mr-2 flex items-center py-[40px]">
          <div className="mr-4">
            <img
              src={data?.profile?.avatar || user_img}
              alt={data?.profile?.name}
              className="w-[150px] h-[150px] rounded-full"
            />
          </div>
          <div>
            <p>Full Name: {data?.profile?.name}</p>
            <p>Email: {data?.email}</p>
            <p>User Name: {data?.profile?.username}</p>
            <p>
              {`Last Updated:
          ${
            data?.profile?.updated_at
              ? format_date_time(data?.profile?.updated_at)
              : ""
          }`}
            </p>
          </div>
        </div>
        <div className="w-[38%] ml-2">
          <div className="my-2">
            <TextInput
              label="Password"
              name="password"
              placeholder="Password"
              type="password"
              value={form.password}
              onChange={onChange}
            />
          </div>

          <div className="my-2">
            <TextInput
              label="Confirm Password"
              name="password_confirmation"
              placeholder="Confirm Password"
              type="password"
              value={form.password_confirmation}
              onChange={onChange}
            />
          </div>

          <div className="my-4">
            <Button
              type="button"
              title="Update Password"
              onClick={() => {
                if (
                  form.password &&
                  form.password === form.password_confirmation
                )
                  update_admin_password(dispatch, form);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdminUser;
