import React from "react";
import { FaEye } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

type IItemAction = {
  delete_loading?: boolean;
  show_view?: boolean;
  show_edit?: boolean;
  show_delete?: boolean;
  onView: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

const ItemAction: React.FC<IItemAction> = ({
  delete_loading,
  show_delete = true,
  show_edit = true,
  show_view = true,
  onDelete,
  onEdit,
  onView,
}) => {
  return (
    <div className="flex items-center gap-3">
      {show_view && (
        <button className="text-gray-500 bg-gray-100 px-3 py-2 rounded-xl">
          <FaEye size={18} onClick={onView} />
        </button>
      )}
      {show_edit && (
        <button className="text-orange-500 bg-[#fff0ea] hover:bg-orange-500 hover:text-white px-3 py-2 rounded-xl">
          <AiFillEdit size={18} onClick={onEdit} />
        </button>
      )}
      {show_delete && (
        <button className="text-orange-500 bg-red-100 hover:bg-[#ef5f5f] hover:text-white px-3 py-2 rounded-xl">
          {delete_loading ? (
            ""
          ) : (
            <MdOutlineDelete size={18} onClick={onDelete} />
          )}
        </button>
      )}
    </div>
  );
};

export default ItemAction;
