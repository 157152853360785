import React from "react";
import {
  delete_opportunity,
  get_opportunities,
} from "../../../services/apis/opportunity";
import { useAppDispatch, useAppSelector } from "../../../store";
import DataTable from "../../../component/element/table";
import { ColumnDef } from "@tanstack/react-table";
import {
  IOpportunity,
  IOpportunityTypes,
} from "../../../store/types/opportunity";
// import EditOportunity from "./EditOportunity";
import ItemAction from "../../../component/ui/action/ItemAction";
import { useNavigate } from "react-router-dom";

const OpportunityTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const opportuities = useAppSelector(
    ({ opportunity }) => opportunity.opportunities
  );
  const onLoad = React.useCallback(() => {
    get_opportunities(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: ColumnDef<IOpportunity>[] = [
    {
      accessorKey: "opportunity_image",
      header: "Image",
      cell: ({ getValue }) => {
        const image = getValue() as string;
        return (
          <img
            src={image}
            alt={image}
            className="w-[3rem] h-[3rem] overflow-hidden rounded-lg"
          />
        );
      },
    },
    {
      accessorKey: "opportunity_name",
      header: "Name",
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: ({ getValue }) => {
        const status = getValue() as IOpportunityTypes;
        return (
          <span className="px-2 py-1 rounded-md  bg-primary">
            {status?.name}
          </span>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }) => {
        const status = getValue() as string;
        return (
          <span
            className={`px-2 py-1 rounded-md ${status === "1" ? "bg-primary" : "bg-gray"}`}
          >
            {status === "1" ? "active" : "not active"}
          </span>
        );
      },
    },
    {
      accessorKey: "id",
      header: "Action",
      cell: ({ getValue }) => {
        const id = getValue() as string;
        return (
          <ItemAction
            show_edit={false}
            onEdit={() => null}
            onView={() => navigate(`/opportunity/${id}`)}
            onDelete={() => delete_opportunity(id, dispatch)}
          />
        );
      },
    },
  ];

  return (
    <>
      <DataTable columns={columns} data={opportuities} />
      {/* <EditOportunity
          show={edit.show}
          opportunity={edit.data}
          onHandler={() => editSet({ show: false, data: null })}
        /> */}
    </>
  );
};

export default OpportunityTable;
