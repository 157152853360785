/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// request
import axiosUpload from "../../utility/axiosUpload";

// react router dom
import { useNavigate } from "react-router-dom";

const useUpload = () => {
  const navigate = useNavigate();
  const uploadFile = async ({ file, setFile, setLoading, dispatch }) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        transformRequest: () => {
          return formData;
        },
      };
      const response = await axiosUpload(navigate).post(
        "/upload",
        formData,
        config
      );
      console.log("Response:", response.data);
      setFile(response.data);
    } catch (error) {
      console.log("Error:", error.response);
    }
    setLoading(false);
  };

  return { uploadFile };
};

export default useUpload;
