import React from "react";

// components
import { ColumnDef } from "@tanstack/react-table";
import { ISimpleData, IStateCountry } from "../../../store/types/misc";
import DataTable from "../../../component/element/table";

// constants and icon
import { FaEye } from "react-icons/fa";
import { format_date_time } from "../../../services/convert/date";

// router and services
import { useNavigate } from "react-router-dom";
import { get_service_requests } from "../../../services/apis/requests";
import { useAppDispatch, useAppSelector } from "../../../store";
import { IRequest } from "../../../store/types/request";

const ServiceRequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const requests = useAppSelector(({ request }) => request.requests);
  // const loading = useAppSelector(({ request }) => request.loading);
  const current_page = useAppSelector(({ request }) => request.current_page);

  const onLoad = React.useCallback(() => {
    get_service_requests(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns: ColumnDef<IRequest>[] = [
    {
      accessorKey: "service",
      header: () => <span className="text-center">Name</span>,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <span className="text-center">
            {data?.service?.name || data?.document?.name}
          </span>
        );
      },
    },
    {
      accessorKey: "state",
      header: () => <span className="text-center">Location</span>,
      cell: ({ getValue }) => {
        const state = getValue() as IStateCountry;
        return (
          <span className="text-center capitalize">
            {`${state.name}, ${state.country.name}`}
          </span>
        );
      },
    },
    {
      accessorKey: "payment_link",
      header: () => <span className="text-center">Order Type</span>,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <span className="text-center bg-primary-400 px-2 py-1 rounded-md text-gray">
            {data.service ? "service" : "document"}
          </span>
        );
      },
    },
    {
      accessorKey: "request_status",
      header: () => <span className="text-center">Status</span>,
      cell: ({ getValue }) => {
        const request_status = getValue() as ISimpleData;
        return <span className="text-center ">{request_status?.name}</span>;
      },
    },
    {
      accessorKey: "created_at",
      header: () => <span className="text-center">Order Date</span>,
      cell: ({ getValue }) => {
        const created_at = getValue() as Date;
        return (
          <span className="text-center capitalize">
            {created_at && format_date_time(created_at)}
          </span>
        );
      },
    },
    {
      accessorKey: "id",
      header: () => <span className="text-center">Action</span>,
      cell: ({ getValue }) => {
        const id = getValue() as string;
        return (
          <FaEye
            size={25}
            color="blue"
            onClick={() => navigate("/request/" + id)}
            className="cursor-pointer"
          />
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <h2 className="font-bold text-[1.4rem] capitalize">Requests</h2>

      <DataTable columns={columns} data={requests} />
    </div>
  );
};

export default ServiceRequest;
